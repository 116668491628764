import React , { useState , useEffect , useRef } from "react";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaGasPump, FaTachometerAlt, FaCogs, FaBatteryHalf } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link , useNavigate } from "react-router-dom"; // Import Link from react-router-dom
import '../styles/NewCard.css';
import { useTranslation } from 'react-i18next';
const NewCardHome = ({ carid, carName, description, specs, price, location, images, isNew }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { t , i18n } = useTranslation();
  const [isRTL, setIsRTL] = useState(i18n.language === 'ar');
  // const sliderRef = useRef(null); // Always call the ref here at the top

  // Custom left arrow component
  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <FaChevronLeft
        className={className}
        style={{ ...style, ...arrowStyle, left: "10px" }}
        onClick={onClick}
      />
    );
  };

  // Custom right arrow component
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <FaChevronRight
        className={className}
        style={{ ...style, ...arrowStyle, right: "10px" }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    rtl: isRTL, // Enable RTL only for Arabic
    prevArrow: <SamplePrevArrow />, // Custom left arrow
    nextArrow: <SampleNextArrow />, // Custom right arrow
  };

  // // This useEffect will reset the slider after language change
  // useEffect(() => {
  //   // Reset slider after RTL change
  //   if (sliderRef.current) {
  //     sliderRef.current.slickGoTo(0); // Go back to the first slide
  //   }
  // }, [isRTL]); // Runs when isRTL state changes

  useEffect(() => {
    const updateRTL = () => {
      setIsRTL(i18n.language === "ar");
    };

    updateRTL(); // Sync initially
    i18n.on("languageChanged", updateRTL);

    return () => {
      i18n.off("languageChanged", updateRTL); // Cleanup listener
    };
  }, [i18n]);

  return (
    <Link to={`/car/${carid}`} style={styles.link}> {/* Wrap the card with a Link */}
      <div style={{
          ...styles.card,
          transform: isHovered ? "scale(1.1)" : "scale(1)",
          transition: "transform 0.3s ease-in-out",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <div style={styles.imageWrapper}>
        <Slider  {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Car ${index + 1}`} style={styles.image} />
              </div>
            ))}
          </Slider>
          {isNew && <div style={styles.newLabel}>{t("NEW")}</div>}
        </div>

        <div style={styles.details}>
          <h3 style={styles.carTitle}>{carName}</h3>
          <p style={styles.carDescription}>{description}</p>
          <div style={styles.specs}>
            {specs.map((spec, index) => (
              <div key={index} style={styles.specItem}>
                {spec.icon} {spec.value}
              </div>
            ))}
          </div>

          <div style={styles.footer}>
            <div style={styles.price}>{price.toUpperCase()}</div>
            <div style={styles.location}>{location}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

// Styles for custom arrows
const arrowStyle = {
  display: "block",
  color: "white", // Arrow color
  fontSize: "20px", // Adjust size
  zIndex: 1,
  position: "absolute",
  top: "50%", // Center vertically
  transform: "translateY(-50%)",
  cursor: "pointer",
};
const styles = {
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  card: {
    width: "350px",
    borderRadius: "10px",
    overflow: "hidden",
    backgroundColor: "#1d1d1d",
    color: "#fff",
    fontFamily: "Everett",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
    borderColor: "#CD9834",
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between", // Keep content aligned within the card
    height: "350px", // Ensure consistent card height
  },
  imageWrapper: {
    position: "relative",
    width: "100%",
    overflow: "hidden",
  },
  image: {
    width: "100%", // Make the image fit the card's width
    height: "197px", // Keep the height fixed
    // objectFit: "cover", // Maintain aspect ratio and crop if necessary
    // mb: 1,
    objectFit: "cover",
    borderRadius: "5px",
  },
  
  newLabel: {
    position: "absolute",
    top: "10px",
    left: "10px",
    backgroundColor: "#fff",
    color: "#000",
    padding: "5px 10px",
    fontSize: "12px",
    borderRadius: "5px",
    fontWeight: "bold",
  },
  details: {
    padding: "20px",
    justifyContent:"center",
  },
  carTitle: {
    margin: "0 0 10px",
    fontSize: "18px",
    fontWeight: "bold",
    whiteSpace: "nowrap", // Keep title on a single line
    overflow: "hidden",
    textOverflow: "ellipsis", // Add ellipsis if text is too long
    fontFamily:"Everett"
  },
  carDescription: {
    margin: "0 0 15px",
    fontSize: "14px",
    color: "#aaa",
    fontFamily:"Everett"
  },
  specs: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
    fontFamily:"Everett"
  },
  specItem: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    color: "#aaa",
    fontFamily:"Everett"
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  price: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#B6862D",
    fontFamily:"Everett"
  },
  location: {
    fontSize: "12px",
    color: "#aaa",
    fontFamily:"Everett"
  },
};

export default NewCardHome;
