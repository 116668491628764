import React from 'react';
import '../styles/PrivacyPolicy.css';
import Footer from '../components/Footer';
const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-policy-page">
      {/* Hero Section */}
      <section className="hero-section">
        <h1>Privacy Policy</h1>
        <p>Your privacy is of utmost importance to us. Learn how we protect your information.</p>
      </section>

      {/* Content Section */}
      <section className="content-section">
        <h2>Falcons Privacy Policy</h2>
        <p>
        We Falcons Group committed to protect all Customer information and data that we may ask for, Our purpose of collecting data is to improve our customer services & to meet customers demands and satisfaction.
        </p>

        <h2>Information Collection</h2>
        <p>
          Collecting customers information will be from different platforms as below:
          - Emails
          - Electronic messages (chatting applications)
          - Phone calls
        </p>

        {/* <h2>Usage of Information</h2>
        <p>
          The information we collect helps us provide and improve our services. We use it to understand customer needs, communicate 
          offers, and ensure the best user experience.
        </p> */}

        {/* <h2>Data Protection</h2>
        <p>
          We take data protection seriously and implement advanced security measures to protect your information from unauthorized 
          access, disclosure, or destruction.
        </p> */}

        <h2>Contact Us</h2>
        <p>
        If you have any concerns or questions about our privacy policy, send us email to info@falconsgt.com
        </p>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
