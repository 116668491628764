import React from "react";
import { FaWhatsapp } from "react-icons/fa";

const WhatsAppButton = () => {
  const phoneNumber = "971564145000"; // Replace with your WhatsApp number
  const message = "Hello, I would like to know more about your services."; // Pre-filled message

  return (
    <a
      href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
      style={styles.floatingButton}
    >
      <FaWhatsapp style={styles.icon} />
    </a>
  );
};

const styles = {
  floatingButton: {
    position: "fixed",
    bottom: "20px",
    left: "20px", // Changed from 'right' to 'left'
    backgroundColor: "#CD9834",
    color: "#fff",
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
    zIndex: 1000,
    textDecoration: "none",
  },
  icon: {
    fontSize: "30px",
  },
};

export default WhatsAppButton;
