import React, { useState } from 'react';
import '../styles/ContactUs.css'; // Create this CSS file for styling
import bg from "../assets/images/pexels-tdcat-70912.webp"; // Using the imported image directly
import Footer from '../components/Footer';
import { Box, Typography, TextField, Button } from "@mui/material";
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ContactUs = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize the navigate function
  const initialFormState = {
    name: '',
    email: '',
    phone: '',
  };
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [submissionStatus, setSubmissionStatus] = useState({
    open: false,
    message: '',
    success: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission status
  const [formError, setFormError] = useState(''); // Track form errors

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitted) return; // Prevent resubmission if already submitted
    // Validate mandatory fields
    if (!formData.name || !formData.email || !formData.phone) {
      setFormError('Please fill in all mandatory fields.');
      return;
    }

    // Refresh access token and make Zoho API call
    try {
      await sendLeadDataToZoho();
      setIsSubmitted(true); // Set submitted state to true
      setFormData(initialFormState); // Clear the form
      setFormError(''); // Clear error message on successful submission
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const sendLeadDataToZoho = async () => {
    // const fullPhone = `${formData.extension} ${formData.phone}`;
    const leadData = {
      company: 'Your Company',
      first_name: formData.name,
      last_name: 'Unknown',
      email: formData.email,
      phone: formData.phone,
      brand: 'Your Brand',
      Website: 'https://falcons-luxury.com/contact',
    };

    try {
      const response = await fetch('https://falconsgt.com/zoho_api.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': '*/*',
        },
        body: JSON.stringify(leadData),
      });

      const data = await response.json();
      console.log("Full Response:", data);

      if (!response.ok) {
        console.error("Server Error Details:", data);
        throw new Error(`Error ${response.status}: ${data.message || 'Unknown error'}`);
      }

      if (data.data) {
        setSubmissionStatus({
          open: true,
          message: 'Form submitted successfully!',
          success: true,
        });
      } else {
        console.error("Error Details:", data);
        setSubmissionStatus({
          open: true,
          message: 'Error submitting form.',
          success: false,
        });
      }
    } catch (error) {
      console.error("Request failed:", error);
      setSubmissionStatus({
        open: true,
        message: 'Network error submitting form.',
        success: false,
      });
    }
  };
  return (
    <div className="contact-page">
      {/* Contact Section */}
      <div className="contact-us-section">
        {/* Two columns: left with text, right with form */}
        <div className="contact-content">
          {/* Left Section - Title and Text */}
          <div className="contact-left">
            <h2>{t('CONTACT_US_TITLE')}</h2>
            <Typography className="info-item">
              <a
                href="https://www.google.com/maps/place/25%C2%B010'18.3%22N+55%C2%B022'10.6%22E/@25.1716849,55.3692794,246m/data=!3m1!1e3!4m4!3m3!8m2!3d25.17175!4d55.3696111?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: 'inherit', fontFamily: 'everett' }}
              >
                <FaLocationDot /> {t('SHOWROOM_ADDRESS')}
              </a>
            </Typography>
            <Typography className="info-item">
              <a
                href="tel:+971564145000"
                style={{ textDecoration: 'none', color: 'inherit', fontFamily: 'everett' }}
              >
                <FaPhoneAlt /> +971 56 414 5000
              </a>
            </Typography>
            <Typography className="info-item">
              <a
                href="mailto:info@falcons-luxury.com"
                style={{ textDecoration: 'none', color: 'inherit', fontFamily: 'everett' }}
              >
                <MdEmail /> info@falcons-luxury.com
              </a>
            </Typography>
            <br />
            <Typography className="info-item" style={{ fontFamily: 'everett' }}>
              <IoMdTime /> {t('OPEN_DAILY')}<br />
              {/* &emsp;&ensp;Sunday: <span className="closed" style={{  fontFamily:'everett' }}>Closed</span> */}
            </Typography>
          </div>

          {/* Right Section - Form */}
          <div className="contact-right">
            <form onSubmit={handleSubmit} className="contact-form">
              <input
                type="text"
                name="name"
                placeholder={t('YOUR_NAME')}
                required
                value={formData.name}
                onChange={handleChange}
              />
              <input
                type="email"
                name="email"
                placeholder={t('YOUR_EMAIL')}
                required
                value={formData.email}
                onChange={handleChange}
              />
              <input
                type="text"
                name="phone"
                placeholder={t('YOUR_PHONE')}
                required
                value={formData.phone}
                onChange={handleChange}
              />
              <textarea
                name="message"
                placeholder={t('YOUR_MESSAGE')}
                required
                value={formData.message}
                onChange={handleChange}
              />
              <button type="submit">{t('SUBMIT_BUTTON')}</button>
            </form>
            {/* Error Message */}
            {formError && <p style={{ color: 'red' }}>{formError}</p>}
            {/* Submission Status Dialog */}
            {submissionStatus.open && (
              <div
                style={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: '#383938',
                  padding: '20px',
                  boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                  textAlign: 'center',
                }}
              >
                <p>{submissionStatus.message}</p>
                <button onClick={() => navigate('/ThankYouPage')}> {/* Navigate to new page */}
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Box className="map-container">
        <iframe
          title="Location Map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d17133.12844178808!2d55.3693399!3d25.1717142!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f67da83084ab1%3A0xf40a690b35c18167!2sFALCONS%20LUXURY%20MOTORS%2005!5e1!3m2!1sen!2sae!4v1731061634091!5m2!1sen!2sae"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </Box>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ContactUs;
