import React from 'react';
import '../styles/Gallery.css';
import tool1 from "../assets/images/gallery/124.jpg";
import tool2 from "../assets/images/gallery/213werqd.jpg";
import tool3 from "../assets/images/gallery/142521.jpg";
import tool4 from "../assets/images/gallery/155125.jpg";
import tool5 from "../assets/images/gallery/13254152.jpg";
import tool6 from "../assets/images/gallery/314575341.jpg";
import tool7 from "../assets/images/gallery/1245122312.jpg";
import tool8 from "../assets/images/gallery/3147653154.jpg";
import tool9 from "../assets/images/gallery/4367658568.jpg";
import tool10 from "../assets/images/gallery/13473145639.jpg";
import tool11 from "../assets/images/gallery/14987128379.jpg";
import tool12 from "../assets/images/gallery/15376114515.jpg";
import tool13 from "../assets/images/gallery/34173476346.jpg";
import tool14 from "../assets/images/gallery/137474315431.jpg";
import tool15 from "../assets/images/gallery/412344123132.jpg";
import tool16 from "../assets/images/gallery/112312323423123.jpg";
import tool17 from "../assets/images/gallery/162127216267621.jpg";
import tool18 from "../assets/images/gallery/534146341345341.jpg";
import tool19 from "../assets/images/gallery/1325141232312312.jpg";
import tool20 from "../assets/images/gallery/4126531643254234.jpg";
import tool21 from "../assets/images/gallery/14241232312312341.jpg";
import tool22 from "../assets/images/gallery/125156113632452324234.jpg";
import tool23 from "../assets/images/gallery/1346435745645264562.jpg";
import tool24 from "../assets/images/gallery/1347673415635345345.jpg";
import tool25 from "../assets/images/gallery/14623426346234523435234.jpg";
import tool26 from "../assets/images/gallery/15436734734535213435.jpg";
import tool27 from "../assets/images/gallery/32423461234123232323.jpg";
import tool28 from "../assets/images/gallery/412213142533252423423.jpg";
import tool29 from "../assets/images/gallery/Untitled-1123.jpg";
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
const Gallery = () => {
  const { t } = useTranslation();
  // Sample images for the gallery
  const galleryImages = [
    { src: tool1, alt: 'Gallery Image 1' },
    { src: tool2, alt: 'Gallery Image 2' },
    { src: tool3, alt: 'Gallery Image 3' },
    { src: tool4, alt: 'Gallery Image 4' },
    { src: tool5, alt: 'Gallery Image 5' },
    { src: tool6, alt: 'Gallery Image 6' },
    { src: tool7, alt: 'Gallery Image 7' },
    { src: tool8, alt: 'Gallery Image 8' },
    { src: tool9, alt: 'Gallery Image 9' },
    { src: tool10, alt: 'Gallery Image 10' },
    { src: tool11, alt: 'Gallery Image 11' },
    { src: tool12, alt: 'Gallery Image 12' },
    { src: tool13, alt: 'Gallery Image 13' },
    { src: tool14, alt: 'Gallery Image 14' },
    { src: tool15, alt: 'Gallery Image 15' },
    { src: tool16, alt: 'Gallery Image 16' },
    { src: tool17, alt: 'Gallery Image 17' },
    { src: tool18, alt: 'Gallery Image 17' },
    { src: tool19, alt: 'Gallery Image 17' },
    { src: tool20, alt: 'Gallery Image 17' },
    { src: tool21, alt: 'Gallery Image 17' },
    { src: tool22, alt: 'Gallery Image 17' },
    { src: tool23, alt: 'Gallery Image 17' },
    { src: tool24, alt: 'Gallery Image 17' },
    { src: tool25, alt: 'Gallery Image 17' },
    { src: tool26, alt: 'Gallery Image 17' },
    { src: tool27, alt: 'Gallery Image 17' },
    { src: tool28, alt: 'Gallery Image 17' },
    { src: tool29, alt: 'Gallery Image 17' },

  ];

  return (
    <div className="gallery-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>{t('OUR_GALLERY_TITLE')}</h1>
          <p>{t('GALLERY_DESCRIPTION')}</p>
        </div>
      </section>

      {/* Gallery Section */}
      <section className="gallery-section">
        {galleryImages.map((image) => (
          <div key={image.id} className="gallery-item">
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </section>

      <Footer />
    </div>
  );
};

export default Gallery;
