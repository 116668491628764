import React from 'react';
import '../styles/TopOffersPage.css';
import Footer from '../components/Footer';
import { Box, Typography } from '@mui/material';
import offerImage1 from '../assets/images/pexels-tinchflicks-29028969.jpg'; // Import your image
import offerImage2 from '../assets/images/pexels-lynxexotics-4141954.jpg'; // Import other images
import offerImage3 from '../assets/images/pexels-hyundaimotorgroup-20614307.jpg'; // Import other images
import offerImage4 from '../assets/images/pexels-vie-studio-4439444.jpg'; // Import other images
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useTranslation } from 'react-i18next';
const TopOffersPage = () => {
  const { t } = useTranslation();
  const offers = []; // Set to empty array to simulate no offers

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // For tablets and smaller devices
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="offers-page">
      {/* Hero Section */}
      <section className="hero-section-offers">
        <h1>{t('TOP_OFFERS_TITLE')}</h1>
        <p>{t('TOP_OFFERS_DESCRIPTION')}</p>
      </section>

      {/* Offers Slider Section */}
      {offers.length > 0 ? (
        <section className="offers-section">
          <Slider {...sliderSettings} className="offers-slider">
            {offers.map((offer) => (
              <div className="offer-card" key={offer.id}>
                <div className="image-container">
                  <img src={offer.image} alt={offer.title} className="offer-image" />
                  {offer.price && (
                    <div className="price-tag">
                      <span>{offer.price}</span>
                    </div>
                  )}
                </div>
                <div className="offer-content">
                  <h2>{offer.title}</h2>
                  <p>{offer.description}</p>
                  <a href={offer.link} className="offer-button">{t('LEARN_MORE')}</a>
                </div>
              </div>
            ))}
          </Slider>
        </section>
      ) : (
        <section className="no-offers-section">
          <div className="no-offers-content">
          <h2>{t('COMING_SOON_TITLE')}</h2>
          <p>{t('COMING_SOON_DESCRIPTION')}</p>
            <img
              src={offerImage4}
              alt="No Offers Available"
              className="no-offers-image"
            />
          </div>
        </section>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default TopOffersPage;
