import React, { useState, useEffect } from 'react';
import { FaSearch, FaSlidersH } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const CarSearchBar = () => {
  const [make, setMake] = useState('');
  const [model, setModel] = useState('');
  const [location, setLocation] = useState('');
  const [models, setModels] = useState([]);
  const [year, setYear] = useState('');
  const [fuel, setFuel] = useState('');
  const [gear, setGear] = useState('');
  const [cylinder, setCylinder] = useState('');
  const [modification, setModification] = useState('');
  const [code, setCode] = useState('');
  const modelData = {
    "11": [
      { id: "65", name: "GLE" },
      { id: "68", name: "E200" },
      { id: "69", name: "G63" },
      { id: "88", name: "S580" },
      { id: "106", name: "S500" },
      { id: "114", name: "SL63" },
      { id: "135", name: "GLS 600" },
      { id: "138", name: "EQS" },
      { id: "154", name: "C200" }
    ],
    "15": [
      { id: "76", name: "Range Rover" },
      { id: "107", name: "Defender" },
      // { id: 12, name: "Range Rover" }
    ],
    "27": [
      { id: "102", name: "Q8" },
      // { id: 14, name: "A4" },
      // { id: 15, name: "Q5" }
    ],
    "6": [
      { id: "16", name: "LX570" },
      { id: "19", name: "GX460" },
      { id: "90", name: "LX600" },
      { id: "124", name: "ES350" }
    ]
  };
  // Update the models based on selected make
  useEffect(() => {
    if (make) {
      setModels(modelData[make] || []); // Set models for the selected make
      setModel(''); // Reset model when make changes
    } else {
      setModels([]);
    }
  }, [make]);
  const navigate = useNavigate();
  const handleSearch = () => {
    const queryParams = new URLSearchParams();

    if (make) queryParams.append('m_id', make);
    if (model) queryParams.append('md_id', model);
    if (year) queryParams.append('model_year', year);
    if (fuel) queryParams.append('fuel', fuel);
    if (gear) queryParams.append('gear', gear);
    if (cylinder) queryParams.append('cylinder', cylinder);
    if (modification) queryParams.append('modified', modification === 'Modified' ? '1' : '0');
    if (code) queryParams.append('code', code);

    navigate(`/vehicles-search-page?${queryParams.toString()}`);
  };
  const { t } = useTranslation();
  return (
    <div style={styles.searchBarContainer}>
      <div style={styles.dropdownContainer}>
        {/* Make Dropdown */}
        <select
          value={make}
          onChange={(e) => setMake(e.target.value)}
          style={styles.dropdown}
        >
          <option value="" disabled>{t('SELECT_MAKE')}</option>
          <option value="11">{t('MERCEDES_BENZ')}</option>
          <option value="15">{t('LAND_ROVER')}</option>
          <option value="27">{t('AUDI')}</option>
          <option value="6">{t('LEXUS')}</option>
        </select>

        {/* Model Dropdown */}
        <select
          value={model}
          onChange={(e) => setModel(e.target.value)}
          style={styles.dropdown}
          disabled={!make} // Disable model dropdown if no make is selected
        >
          <option value="" disabled>{t('SELECT_MODEL')}</option>
          {models.length > 0 ? (
            models.map((model) => (
              <option key={model.id} value={model.id}>
                {model.name}
              </option>
            ))
          ) : (
            <option value="" disabled>{t('NO_MODELS_AVAILABLE')}</option>
          )}
        </select>

        {/* Sub-model Dropdown */}
        {/* <select 
          value={subModel} 
          onChange={(e) => setSubModel(e.target.value)} 
          style={styles.dropdown}
        >
          <option value="" disabled>Select Sub-model</option>
          <option value="LE">LE</option>
          <option value="SE">SE</option>
          <option value="XLE">XLE</option>
        </select> */}

        {/* Year Dropdown */}
        <select
          value={year}
          onChange={(e) => setYear(e.target.value)}
          style={styles.dropdown}
        >
          <option value="" disabled>{t('SELECT_YEAR')}</option>
          <option value="2023">2025</option>
          <option value="2022">2024</option>
          <option value="2021">2023</option>
        </select>

        {/* Fuel Dropdown */}
        {/* <select
          value={fuel}
          onChange={(e) => setFuel(e.target.value)}
          style={styles.dropdown}
        >
          <option value="" disabled>Select Fuel</option>
          <option value="Petrol">Petrol</option>
          <option value="Diesel">Diesel</option> */}
          {/* <option value="Electric">Electric</option> */}
        {/* </select> */}

        {/* Gear Dropdown */}
        {/* <select
          value={gear}
          onChange={(e) => setGear(e.target.value)}
          style={styles.dropdown}
        >
          <option value="" disabled>Select Transmission</option>
          <option value="Automatic">Automatic</option>
          <option value="Manual">Manual</option>
        </select> */}

        {/* Cylinder Dropdown */}
        <select
          value={cylinder}
          onChange={(e) => setCylinder(e.target.value)}
          style={styles.dropdown}
        >
          <option value="" disabled>{t('SELECT_CYLINDER')}</option>
          <option value="4">{t('4_CYLINDERS')}</option>
          <option value="6">{t('6_CYLINDERS')}</option>
          <option value="8">{t('8_CYLINDERS')}</option>
        </select>

        {/* Modification Dropdown */}
        {/* <select
          value={modification}
          onChange={(e) => setModification(e.target.value)}
          style={styles.dropdown}
        >
          <option value="" disabled>Select Modification</option>
          <option value="0">Stock</option>
          <option value="1">Modified</option>
        </select> */}

        {/* Code Input */}
        {/* <input
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter Code"
          style={styles.textInput}
        /> */}
      </div>

      {/* Filter Icon */}
      {/* <div style={styles.iconContainer}>
        <FaSlidersH style={styles.icon} />
      </div> */}

      {/* Search Button */}
      <button onClick={handleSearch} style={styles.searchButton}>
        <FaSearch style={styles.searchIcon} /> {t('SEARCH_CARS')}
      </button>
    </div>
  );
};

const styles = {
  searchBarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#292929',
    padding: '20px',
  },
  dropdownContainer: {
    display: 'flex',
    // flexGrow: 1,
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  dropdown: {
    backgroundColor: '#333',
    color: '#fff',
    border: '1px solid #555',
    borderRadius: '4px',
    padding: '10px',
    margin: '5px 10px',
    width: '150px',
    fontSize: '14px',
    appearance: 'none', // Remove native select dropdown appearance
    fontFamily: 'everett',

  },
  textInput: {
    backgroundColor: '#333',
    color: '#fff',
    border: '1px solid #555',
    borderRadius: '4px',
    padding: '10px',
    margin: '5px 10px',
    width: '150px',
    fontSize: '14px',
    fontFamily: 'everett',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  icon: {
    color: '#fff',
    fontSize: '20px',
  },
  searchButton: {
    backgroundColor: '#B6862D',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    fontSize: '14px',
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontFamily: 'everett',
  },
  searchIcon: {
    marginRight: '5px',
  },
};

export default CarSearchBar;
