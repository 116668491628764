import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useCookies } from "react-cookie";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  debug: true,
  interpolation: {
    escapeValue: false,
  },
  lng: "en",
  resources: {
    en: {
      translations: require("./locales/en/translations.json"),
    },
    ar: {
      translations: require("./locales/ar/translations.json"),
    },
    fr:{
      translations: require("./locales/fr/translations.json"),
    }
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["en", "ar","fr"];

export default i18n;
