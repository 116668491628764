import React from 'react';
import '../styles/StatisticsSection.css'; // Make sure to add your custom CSS here
import CarBrandSlider from '../components/CarBrandSlider';
import VehicleExploreSection from '../components/VehicleExploreSection';
import VehicleTypeSection from '../components/VehicleTypeSection';
import CarCarousel   from '../components/CarCarousel';
import CarBrandsSliderNew   from '../components/CarBrandsSliderNew';

const StatisticsSection = () => {
  return (
    <section className="statistics-section">
      <div className="car-brand-slider2">
        <CarBrandsSliderNew />
      </div>
    </section>
  );
};

export default StatisticsSection;
