import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Button, Grid, TextField } from '@mui/material';
import { Carousel } from 'react-responsive-carousel'; // Add a carousel for the slider
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import bannerImage from '../assets/images/pexels-pixabay-261985.jpg'; // Replace with your banner image
import carImage1 from '../assets/images/001.webp'; // Replace with actual car images
import carImage2 from '../assets/images/002.webp';
import carImage3 from '../assets/images/003.webp';
import carImage4 from '../assets/images/004.webp';
import carImage5 from '../assets/images/005.webp';
import carImage6 from '../assets/images/006.webp';
import carImage7 from '../assets/images/007.webp';
import carImage8 from '../assets/images/008.webp';
import '../styles/CarDetailsPage.css';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SpeedIcon from '@mui/icons-material/Speed';
import BuildIcon from '@mui/icons-material/Build';
import Footer from '../components/Footer';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import ShareIcon from '@mui/icons-material/Share';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import logo from '../assets/images/Falcons Lux Logo.png';
import DownloadIcon from '@mui/icons-material/Download';
const CarDetailsPage = () => {
  const { t } = useTranslation();
  const { carId } = useParams();
  console.log('Car ID:', carId);
  const [carDetails, setCarDetails] = useState(null); // Set up state for car details
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const [featuresArray, setFeaturesArray] = useState([]);
  const [exteriorfeaturesArray, setexteriorfeaturesArray] = useState([]);
  const [interiorfeaturesArray, setinteriorfeaturesArray] = useState([]);
  const navigate = useNavigate(); // Initialize the navigate function
  const initialFormState = {
    name: '',
    email: '',
    phone: '',
  };
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [submissionStatus, setSubmissionStatus] = useState({
    open: false,
    message: '',
    success: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false); // Track submission status
  const [formError, setFormError] = useState(''); // Track form errors
  useEffect(() => {
    // Fetch car details from API
    const fetchCarDetails = async () => {
      try {
        const response = await fetch(`https://app.falconsgt.com/api/Acars/${carId}`); // Replace with your API endpoint
        const data = await response.json();
        setCarDetails(data.car.data); // Update state with API data
        const technicalFeatures = data.car.data.technical_features || '';
        const exteriorfeaturesArray = data.car.data.exterior_features || '';
        const interiorfeaturesArray = data.car.data.interior_features || '';

        // Process technical features into an array of { label, value } objects
        const features = technicalFeatures
          .split('\n')
          .filter(line => line.trim() !== '') // Remove empty lines
          .map(line => {
            const [label, value] = line.split(':');
            return { label: label.trim(), value: value ? value.trim() : '-' };
          });

        setFeaturesArray(features);

        const exteriorfeatures = exteriorfeaturesArray
          .split('\n')
          .filter(line => line.trim() !== '') // Remove empty lines
          .map(line => {
            const [label, value] = line.split(':');
            return { label: label.trim(), value: value ? value.trim() : '-' };
          });

        setexteriorfeaturesArray(exteriorfeatures);

        const interiorfeatures = interiorfeaturesArray
          .split('\n')
          .filter(line => line.trim() !== '') // Remove empty lines
          .map(line => {
            const [label, value] = line.split(':');
            return { label: label.trim(), value: value ? value.trim() : '-' };
          });

        setinteriorfeaturesArray(interiorfeatures);
      } catch (error) {
        console.error('Error fetching car details:', error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchCarDetails();
  }, [carId]);

  if (loading) return <p>{t('LOADING')}</p>;
  if (!carDetails) return <p>{t('NO_CAR_DETAILS')}</p>;

  const prevImage = () => {
    setCurrentIndex((prev) => (prev === 0 ? carDetails.images.data.length - 1 : prev - 1));
  };

  const nextImage = () => {
    setCurrentIndex((prev) => (prev === carDetails.images.data.length - 1 ? 0 : prev + 1));
  };
  const thumbnailClickHandler = (index) => {
    setCurrentIndex(index);
  };
  console.log("Data" + carDetails.maker.data.name);
  const handleShare = () => {
    const pageTitle = document.title;
    const pageUrl = window.location.href;

    if (navigator.share) {
      // Use Web Share API
      navigator
        .share({
          title: pageTitle,
          url: pageUrl,
        })
        .then(() => console.log("Page shared successfully"))
        .catch((error) => console.error("Error sharing", error));
    } else {
      // Fallback: Copy URL to clipboard
      navigator.clipboard.writeText(pageUrl).then(() => {
        alert("Page URL copied to clipboard!");
      });
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  const handleDownload = () => {
    const url = `https://falconsgt.com/vehicle/pdf/id/${carDetails.id}/0`;
  window.open(url, "_blank"); // Open the link in a new tab
  };
  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitted) return; // Prevent resubmission if already submitted
    // Validate mandatory fields
    if (!formData.name || !formData.email || !formData.phone) {
      setFormError('Please fill in all mandatory fields.');
      return;
    }

    // Refresh access token and make Zoho API call
    try {
      await sendLeadDataToZoho();
      setIsSubmitted(true); // Set submitted state to true
      setFormData(initialFormState); // Clear the form
      setFormError(''); // Clear error message on successful submission
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const sendLeadDataToZoho = async () => {
    // const fullPhone = `${formData.extension} ${formData.phone}`;
    const leadData = {
      company: 'Your Company',
      first_name: formData.name,
      last_name: 'Unknown',
      email: formData.email,
      phone: formData.phone,
      brand: 'Your Brand',
      Website: `https://falcons-luxury.com/car/${carDetails.maker.data.name}/${carDetails.model.name}/${carDetails.title}`,
    };

    try {
      const response = await fetch('https://falconsgt.com/zoho_api.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': '*/*',
        },
        body: JSON.stringify(leadData),
      });

      const data = await response.json();
      console.log("Full Response:", data);

      if (!response.ok) {
        console.error("Server Error Details:", data);
        throw new Error(`Error ${response.status}: ${data.message || 'Unknown error'}`);
      }

      if (data.data) {
        setSubmissionStatus({
          open: true,
          message: 'Form submitted successfully!',
          success: true,
        });
      } else {
        console.error("Error Details:", data);
        setSubmissionStatus({
          open: true,
          message: 'Error submitting form.',
          success: false,
        });
      }
    } catch (error) {
      console.error("Request failed:", error);
      setSubmissionStatus({
        open: true,
        message: 'Network error submitting form.',
        success: false,
      });
    }
  };



  return (
    <Box className="car-details-page">
      {/* Banner */}
      <Box className="car_banner" style={{ fontFfamily: 'everett', }}>
        <Typography variant="h5" className="model-name">
          {carDetails.maker.data.name}
        </Typography>
        <Typography variant="h3" className="brand-name">
          {carDetails.model.name} {carDetails.title}
        </Typography>
      </Box>

      <Box className="content" style={{ fontFfamily: 'everett', }} sx={{ width: "100%", mx: "auto" }}>
        {/* Image Slider and Inquiry Form */}
        <Grid container spacing={3}>
          {/* Image Slider */}
          <Grid item xs={12} md={8}>
            <Box className="image-slider-thumbnail-wrapper" sx={{ width: "100%", mx: "auto" }}>
              <div
                className="slider_c"
                style={{
                  marginRight: "auto",
                  marginLeft: "auto",
                }}
              >
                <img
                  src={carDetails.images.data[currentIndex].image_3}
                  alt="Car"
                  style={{ width: "100%", height: "auto" }}
                />
                <span className="prev" onClick={prevImage}>
                  ❮
                </span>
                <span className="next" onClick={nextImage}>
                  ❯
                </span>
                {/* Watermark Image */}
                <img
                  src={logo} // Replace with your watermark image URL
                  alt="Watermark"
                  style={{
                    position: "absolute",
                    top: "50%", // Center vertically
                    left: "50%", // Center horizontally
                    transform: "translate(-50%, -50%)", // Adjust to center precisely
                    opacity: 0.5, // Adjust transparency
                    pointerEvents: "none", // Prevent interaction
                    width: "30%", // Adjust size as needed
                  }}
                />
              </div>
              {/* Full Width Thumbnail Gallery */}
              <Box
                className="thumbnail-gallery"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                {carDetails.images.data.map((image, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      margin: "5px",
                      cursor: "pointer",
                      border: currentIndex === index ? "2px solid #CD9834" : "2px solid transparent",
                    }}
                    onClick={() => thumbnailClickHandler(index)}
                  >
                    {/* Thumbnail Image */}
                    <img
                      src={image.image_2}
                      alt={`Thumbnail ${index + 1}`}
                      style={{
                        width: 100,
                        height: 60,
                        opacity: currentIndex === index ? 1 : 0.6,
                      }}
                    />

                    {/* Watermark Image */}
                    <img
                      src={logo} // Replace with your watermark image URL
                      alt="Watermark"
                      style={{
                        position: "absolute",
                        top: "50%", // Center vertically
                        left: "50%", // Center horizontally
                        transform: "translate(-50%, -50%)", // Adjust to center precisely
                        opacity: 0.5, // Adjust transparency
                        pointerEvents: "none", // Prevent interaction
                        width: "30%", // Adjust size as needed
                      }}
                    />
                  </div>
                ))}
              </Box>
            </Box>
            {/* Icons and Info */}
            <Box style={{ fontFfamily: 'everett', }} sx={{ backgroundColor: '#000', padding: '20px' }}>
              <Grid container spacing={2} alignItems="center" style={{ justifyContent: "center", }}>
                {/* Year */}
                <Grid item xs={6} sm={2}>
                  <Box textAlign="center">
                    <CalendarTodayIcon sx={{ color: '#CD9834', fontSize: 30 }} />
                    <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'everett' }} style={{ fontFfamily: 'everett', }}>
                      {carDetails.model_year}
                    </Typography>
                  </Box>
                </Grid>

                {/* Fuel Type */}
                <Grid item xs={6} sm={2}>
                  <Box textAlign="center">
                    <LocalGasStationIcon sx={{ color: '#CD9834', fontSize: 30 }} />
                    <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'everett' }} style={{ fontFfamily: 'everett', }}>
                      {carDetails.fuel}
                    </Typography>
                  </Box>
                </Grid>

                {/* Transmission */}
                <Grid item xs={6} sm={2}>
                  <Box textAlign="center">
                    <DirectionsCarIcon sx={{ color: '#CD9834', fontSize: 30 }} />
                    <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'everett' }}>
                      {carDetails.transmission}
                    </Typography>
                  </Box>
                </Grid>

                {/* Horsepower */}
                <Grid item xs={6} sm={2}>
                  <Box textAlign="center">
                    <SpeedIcon sx={{ color: '#CD9834', fontSize: 30 }} />
                    <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'everett' }}>
                      V{carDetails.cylinders}
                    </Typography>
                  </Box>
                </Grid>

                {/* Body Style */}
                <Grid item xs={6} sm={2}>
                  <Box textAlign="center">
                    <BuildIcon sx={{ color: '#CD9834', fontSize: 30 }} />
                    <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'everett' }}>
                      {carDetails.type.toUpperCase()}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          {/* Inquiry Form */}
          <Grid item xs={12} md={4}>
            <Box className="form-section" style={{ fontFfamily: 'everett', }}>
              <Typography variant="h5" className="inquiry-title" sx={{ mb: 3, textAlign: "center", fontFamily: 'everett' }}>
                {t('INQUIRE_TITLE')}
              </Typography>
              <form onSubmit={handleSubmit} className="contact-form-d">
                <input
                  type="text"
                  name="name"
                  placeholder={t('PLACEHOLDER_NAME')}
                  required
                  value={formData.name}
                  style={{ width: "100%", marginBottom: "1rem" }}
                  onChange={handleChange}
                />
                <input
                  type="email"
                  name="email"
                  placeholder={t('PLACEHOLDER_EMAIL')}
                  required
                  value={formData.email}
                  style={{ width: "100%", marginBottom: "1rem" }}
                  onChange={handleChange}
                />
                <input
                  type="number"
                  name="phone"
                  placeholder={t('PLACEHOLDER_PHONE')}
                  required
                  value={formData.phone}
                  style={{ width: "100%", marginBottom: "1rem" }}
                  onChange={handleChange}
                />
                <textarea
                  name="message"
                  placeholder={t('PLACEHOLDER_MESSAGE')}
                  required
                  value={formData.message}
                  style={{ width: "100%", marginBottom: "1rem" }}
                  onChange={handleChange}
                ></textarea>
                <button
                  type="submit"
                  style={{
                    width: "106%",
                    padding: "10px",
                    backgroundColor: "#CD9834",
                    color: "#FFF",
                    border: "none",
                  }}
                >
                  {t('SUBMIT_BUTTON')}
                </button>
              </form>
              {/* Error Message */}
              {formError && <p style={{ color: 'red' }}>{formError}</p>}
              {/* Submission Status Dialog */}
              {submissionStatus.open && (
                <div
                  style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: '#383938',
                    padding: '20px',
                    boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                    textAlign: 'center',
                  }}
                >
                  <p>{submissionStatus.message}</p>
                  <button onClick={() => navigate('/ThankYouPage')}> {/* Navigate to new page */}
                    Close
                  </button>
                </div>
              )}
              {/* Icon Row */}
              <Box
                className="icon-row"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 4,
                  mt: 2,
                  color: "#000",
                  marginTop: "20px",
                }}
                style={{ fontFfamily: 'everett', }}
              >
                <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={handleDownload}>
                  <DownloadIcon style={{ color: "#CD9834" }} />
                  <Typography variant="body2" sx={{ ml: 0.5, color: "#CD9834", fontFamily: 'everett' }}>
                    {t('Download')}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={handleShare}>
                  <ShareIcon style={{ color: "#CD9834" }} />
                  <Typography variant="body2" sx={{ ml: 0.5, color: "#CD9834", fontFamily: 'everett' }}>
                    {t('SHARE')}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                  <StarBorderIcon style={{ color: "#CD9834" }} />
                  <Typography variant="body2" sx={{ ml: 0.5, color: "#CD9834", fontFamily: 'everett' }}>
                    {t('SAVE')}
                  </Typography>
                </Box>
                <Box
                  sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                  onClick={() => {
                    const phoneNumber = "971564145000"; // Replace with your WhatsApp number (e.g., country code + number)
                    const whatsappURL = `https://wa.me/${phoneNumber}`;
                    window.open(whatsappURL, "_blank"); // Open the link in a new tab
                  }}
                >
                  <WhatsAppIcon style={{ color: "#CD9834" }} />
                  <Typography
                    variant="body2"
                    sx={{ ml: 0.5, color: "#CD9834", fontFamily: "everett" }}
                  >
                    {t('CHAT')}
                  </Typography>
                </Box>

              </Box>
              {/* Note Section */}
              <Box sx={{ mt: 2, textAlign: "center", color: "#555", marginBottom: "50px", }}>
                <Typography variant="body2">
                  <strong>{t('Note')}</strong> {t('different_colors')}
                </Typography>
              </Box>
            </Box>
            {/* Technical Features */}
            <Box className="details-box" style={{ fontFfamily: 'everett', }} sx={{ border: "1px solid #CCC", borderRadius: 2, p: 3, maxWidth: "80%", marginTop: "10px", }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", fontFamily: 'everett' }} className="description-title" style={{ fontFfamily: 'everett', }}>{t('Technical_Features')}</Typography>
              <table className="car-specs-table" style={{ fontFfamily: 'everett', }}>
                <tbody>
                  {featuresArray.map((feature, index) => (
                    <tr key={index}><td>{feature.label}</td><td>{feature.value || '-'}</td></tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Grid>
        </Grid>


      </Box>

      {/* Specifications */}
      <Box className="specifications" sx={{ mt: 5, margin: "20px" }} style={{ fontFfamily: 'everett', }}>
        <Grid container spacing={3}>
          {/* Order Details */}
          {/* <Grid item xs={12} md={6}>
            <Box className="details-box" sx={{ border: "1px solid #CCC", borderRadius: 2, p: 3 }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }} className="description-title">Car Details</Typography>
              <table className="car-specs-table">
                <tbody>
                  <tr><td>Make</td><td>{carDetails.maker.data.name}</td></tr>
                  <tr><td>Model</td><td>{carDetails.model.name}</td></tr>
                  <tr>
                    <td>Type</td>
                    <td>{carDetails.type}</td>
                  </tr>
                  <tr><td>Year</td><td>{carDetails.model_year}</td></tr>
                  <tr>
                    <td>Transmission</td>
                    <td>{carDetails.transmission}</td>
                  </tr>
                  <tr>
                    <td>Cylinders</td>
                    <td>{carDetails.cylinders}V</td>
                  </tr>
                  <tr>
                    <td>Fuel</td>
                    <td>{carDetails.fuel}</td>
                  </tr>
                  <tr>
                    <td>Code</td>
                    <td>{carDetails.code}</td>
                  </tr>
                  <tr>
                    <td>Location</td>
                    <td>{carDetails.location}</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Grid> */}

          {/* Technical Features */}
          {/* <Grid item xs={12} md={6}>
            <Box className="details-box" sx={{ border: "1px solid #CCC", borderRadius: 2, p: 3 }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }} className="description-title">Technical Features</Typography>
              <table className="car-specs-table">
                <tbody>
                  {featuresArray.map((feature, index) => (
                    <tr key={index}><td>{feature.label}</td><td>{feature.value || '-'}</td></tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Grid> */}

          {/* Interior and Exterior Features */}
          <Grid item xs={12} md={6}>
            <Box className="details-box" sx={{ border: "1px solid #CCC", borderRadius: 2, p: 3 }} style={{ fontFfamily: 'everett', }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", fontFamily: 'everett' }} className="description-title">{t('Interior_Features')}</Typography>
              <table className="car-specs-table">
                <tbody>
                  {interiorfeaturesArray.map((feature, index) => (
                    <tr key={index}><td>{feature.label}</td></tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className="details-box" sx={{ border: "1px solid #CCC", borderRadius: 2, p: 3 }} style={{ fontFfamily: 'everett', }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold", fontFamily: 'everett' }} className="description-title">{t('Exterior_Features')}</Typography>
              <table className="car-specs-table">
                <tbody>
                  {exteriorfeaturesArray.map((feature, index) => (
                    <tr key={index}><td>{feature.label}</td></tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 auto',
        padding: '20px',
        maxWidth: '100%',
        boxSizing: 'border-box', backgroundColor: "#292929",
        fontFfamily: 'everett',
      }}>
        {/* Left Side Information */}
        <div style={styles.infoSection}>
          <h2 style={styles.heading}>{t('showroom')}</h2>
          <p style={styles.address}>
            {t('address')}
          </p>
          <p style={styles.directions}>
            <a href="https://www.google.com/maps" target="_blank" rel="noopener noreferrer" style={styles.link}>
              {t('get_directions')}
            </a>
          </p>
          <p style={styles.contact}>
            <FaPhoneAlt />&nbsp; <a href="tel:971564145000" style={styles.link}>{t('contact_number')}</a>
          </p>
        </div>

        {/* Right Side Google Map */}
        <div style={styles.mapSection}>
          <iframe
            title="showroom-map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d17133.12844178808!2d55.3693399!3d25.1717142!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f67da83084ab1%3A0xf40a690b35c18167!2sFALCONS%20LUXURY%20MOTORS%2005!5e1!3m2!1sen!2sae!4v1731061634091!5m2!1sen!2sae"
            style={styles.map}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </Box>
  );
};
const styles = {
  infoSection: {
    flex: 1,
    paddingRight: '20px',
    paddingLeft: '24px',
  },
  heading: {
    margin: 0,
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  address: {
    margin: '10px 0',
    fontSize: '1em',
  },
  directions: {
    fontSize: '1em',
  },
  link: {
    color: '#E6E7E8',
    textDecoration: 'none',
  },
  contact: {
    fontSize: '1em',
    color: '#B6862D',
    display: 'flex',
    alignItems: 'center',
  },
  mapSection: {
    flex: 1,
    paddingLeft: '20px',
  },
  map: {
    width: '100%',
    height: '300px',
    border: 0,
  },
};
export default CarDetailsPage;
