// import React from 'react';
import '../styles/Navbar.css';
import logo from '../assets/images/Falcons Lux Logo.png'; // Import your image here
// import i18next from "../i18n/config";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { FaGlobe } from 'react-icons/fa';
const Navbar = () => {
  const { t } = useTranslation();
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const vehicles = [
    { name: 'All Vehicles' },
    { name: 'Sedans' },
    { name: 'SUV' },
    { name: 'Coupe' },
    // { name: 'Trucks' },
    // { name: 'Pick Up' },
    // { name: 'Buses & Vans' },
  ];
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [isSubmenuOpen1, setIsSubmenuOpen1] = useState(false);
  const [isSubmenuOpen2, setIsSubmenuOpen2] = useState(false);

  // Toggle submenu visibility on hover or click
  const handleMouseEnter = () => {
    setIsSubmenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSubmenuOpen(false);
  };

  const handleMouseEnter1 = () => {
    setIsSubmenuOpen1(true);
  };

  const handleMouseLeave1 = () => {
    setIsSubmenuOpen1(false);
  };
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // New state for mobile menu

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen); // Toggle mobile menu

  const [isMobileSubmenuOpen, setIsMobileSubmenuOpen] = useState(false);

const toggleMobileSubmenu = () => {
  setIsMobileSubmenuOpen(!isMobileSubmenuOpen);
};
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    // document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
  };
  const toggleLanguageMenu = () => {
    setIsLanguageMenuOpen((prev) => !prev);
  };
  return (
    <nav className="navbar">
      {/* Left side: Menu icon and links */}
      <div className="navbar-left">
        <ul className="nav-links">
          <li><div className="menu-icon" onClick={toggleMobileMenu}>
            <span>&#9776;</span> <span><Link to="/home">{t("HOME")}</Link></span>
          </div>
          </li>
          <li
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="menu-item"
          >
            {t("VEHICLES")}
            <span className="dropdown">&#9662;</span>

            {/* Submenu for vehicles */}
            {isSubmenuOpen && (
              <ul className="submenu">
                {vehicles.map((vehicle, index) => (
                  <li key={index} className="submenu-item"><Link to={`/vehicles-page?name=${encodeURIComponent(vehicle.name)}`}>
                    {vehicle.name}
                  </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li onMouseEnter={handleMouseEnter1}
            onMouseLeave={handleMouseLeave1}
            className="menu-item">
            {t("GALLERY")}
            <span className="dropdown">&#9662;</span>
            {/* Submenu for vehicles */}
            {isSubmenuOpen1 && (
              <ul className="submenu">
                <li className="submenu-item"><Link to="/gallery">{t("IMAGEGALLERY")}</Link></li>
                <li className="submenu-item"><Link to="/videogallery">{t("VIDEOGALLERY")}</Link></li>
              </ul>
            )}

          </li>
        </ul>
      </div>

      {/* Center: Logo */}
      <div className="navbar-logo">
        <img src={logo} alt="Logo" className="logo" />
      </div>

      {/* Right side: Links */}
      <div className={`navbar-right ${isMobileMenuOpen ? 'open' : ''}`}>
        <ul className="nav-links">
          <li><Link to="/top-offers">{t("OFFERS")}</Link></li>
          <li><Link to="/about-us">{t("ABOUT_US")}</Link></li>
          <li><Link to="/contact">{t("CONTACT_US")}</Link></li>
          <li className="language-menu">
            <FaGlobe onClick={toggleLanguageMenu} className="language-icon" />
            {isLanguageMenuOpen && (
              <ul className="language-dropdown">
                <li onClick={() => {
                  changeLanguage('en'); // Change language
                  setIsLanguageMenuOpen(false); // Close the menu
                }} className="submenu-item">
                  English
                </li>
                <li onClick={() => {
                  changeLanguage('ar'); // Change language
                  setIsLanguageMenuOpen(false); // Close the menu
                }} className="submenu-item">
                  العربية
                </li>
                <li onClick={() => {
                  changeLanguage('fr'); // Change language
                  setIsLanguageMenuOpen(false); // Close the menu
                }} className="submenu-item">
                  French
                </li>
              </ul>
            )}
          </li>
          <li
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="menu-item_r"
            onClick={toggleMobileSubmenu}
          >
            {t("VEHICLES")}
            <span className="dropdown">&#9662;</span>

            {/* Submenu for vehicles */}
            {isSubmenuOpen && (
              <ul className={`submenu ${isMobileSubmenuOpen ? 'open' : ''}`}>
                {vehicles.map((vehicle, index) => (
                  <li key={index} className="submenu-item"><Link to={`/vehicles-page?name=${encodeURIComponent(vehicle.name)}`}>
                    {vehicle.name}
                  </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li onMouseEnter={handleMouseEnter1}
            onMouseLeave={handleMouseLeave1}
            className="menu-item_r"
            onClick={toggleMobileSubmenu}>
            {t("GALLERY")}
            <span className="dropdown">&#9662;</span>
            {/* Submenu for vehicles */}
            {isSubmenuOpen1 && (
              <ul className={`submenu ${isMobileSubmenuOpen ? 'open' : ''}`}>
                <li className="submenu-item"><Link to="/gallery">{t("IMAGEGALLERY")}</Link></li>
                <li className="submenu-item"><Link to="/videogallery">{t("VIDEOGALLERY")}</Link></li>
              </ul>
            )}

          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
