import React, { useState } from 'react';
import '../styles/LandingPageNoForm.css'; // Assuming you put the styles in Banner.css
import Footer from '../components/Footer';
import CarGalleryM from '../components/CarGalleryM';
import axios from 'axios';
function LandingPageNoForm() {
  
  return (
    <div className='landing-page-n'>


      <div className="banner-section-loading-n">
        <div className="banner-content-loading-n">
          <div className="banner-text-loading-n">
            <h2>UNLEASH THE THRILL OF LUXURY!</h2>
            <p>Get behind the wheel of the Mercedes-Benz C200 and experience sophistication like never before.</p>
          </div>
        </div>
      </div>
      <CarGalleryM />
      <Footer />
    </div>
  );
}

export default LandingPageNoForm;
