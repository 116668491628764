import React from 'react';
import styled from 'styled-components';
import { FaTiktok, FaInstagram, FaFacebookF, FaYoutube, FaLinkedinIn, FaXing } from 'react-icons/fa';
import { PiXLogoLight } from "react-icons/pi";
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PiTiktokLogoBold } from "react-icons/pi";
// Container for the social media section with responsive design
const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 20px;
  flex-wrap: wrap; /* Make icons wrap on smaller screens */
`;

// Wrapper for each social media icon with hover effects and responsiveness
const IconWrapper = styled.div`
  margin: 10px;
  padding: 15px;
  border: 2px solid #CD9834;  // Gold color
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #CD9834;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
    color: white;
    border-color: white;
  }

  svg {
    font-size: 24px; /* Default size for small screens */
  }

  /* Larger screens: Tablet and Desktop */
  @media (min-width: 768px) {
    svg {
      font-size: 32px; /* Larger icons for tablets */
    }
  }

  @media (min-width: 1024px) {
    svg {
      font-size: 36px; /* Larger icons for desktops */
    }
  }
`;

// Section title and description styling with responsiveness
const SectionTitle = styled(Typography)`
  color: white;
  font-family: 'Miller Display', serif;
  font-style: italic;
  font-size: 24px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 28px; /* Slightly larger font for tablets */
  }

  @media (min-width: 1024px) {
    font-size: 32px; /* Larger title for desktops */
  }
`;

const SectionDescription = styled(Typography)`
  color: white;
  font-family: 'Miller Display', serif;
  font-size: 14px;
  text-align: center;
  
  @media (min-width: 768px) {
    font-size: 16px; /* Larger text for tablets */
  }

  @media (min-width: 1024px) {
    font-size: 18px; /* Larger text for desktops */
  }
`;

const SocialMediaIcons = () => {
  const { t } = useTranslation();
  return (
    <Box className="social-media-section" style={{ backgroundImage: 'url(../assets/images/LUX BG.png)' }}>
      {/* Section Title */}
      <SectionTitle variant="h4">{t('FIND_US_TITLE')}</SectionTitle>

      {/* Description */}
      <SectionDescription variant="body1">
        {t('FIND_US_DESCRIPTION')}
      </SectionDescription>

      <SocialMediaContainer>
        <a href="https://www.linkedin.com/company/falcons-luxury" target="_blank" rel="noopener noreferrer">
          <IconWrapper>
            <FaLinkedinIn />
          </IconWrapper>
        </a>
        <a href="https://www.instagram.com/falconsluxurymotors/" target="_blank" rel="noopener noreferrer">
          <IconWrapper>
            <FaInstagram />
          </IconWrapper>
        </a>
        <a href="https://www.facebook.com/FalconsLuxuryMotors" target="_blank" rel="noopener noreferrer">
          <IconWrapper>
            <FaFacebookF />
          </IconWrapper>
        </a>
        <a href="https://x.com/falconslux" target="_blank" rel="noopener noreferrer">
          <IconWrapper>
            <PiXLogoLight />
          </IconWrapper>
        </a>
        <a href="https://www.youtube.com/@falconsluxurymotors" target="_blank" rel="noopener noreferrer">
          <IconWrapper>
            <FaYoutube />
          </IconWrapper>
        </a>
        <a href="https://www.tiktok.com/@falconslux" target="_blank" rel="noopener noreferrer">
          <IconWrapper>
            <PiTiktokLogoBold />
          </IconWrapper>
        </a>
      </SocialMediaContainer>
    </Box>
  );
};

export default SocialMediaIcons;
