import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, TextField, Button, MenuItem } from "@mui/material";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
const brands = {
  Mercedes_Benz: ["C200", "GLS 600", "S580", "GLE"],
  Land_Rover: ["Defender", "Range Rover"],
  Lexus: ["LX600"],
  Audi: ["A3", "A4", "Q8"],
};

const FloatingInquiryButton = () => {
  const [open, setOpen] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState({
    message: "",
    success: false,
  });

  const navigate = (url) => {
    window.location.href = url; // Simulates navigation
  };

  const initialFormState = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData(initialFormState);
    setSelectedBrand("");
    setSelectedModel("");
    setFormError({});
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
    setSelectedModel(""); // Reset model selection
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = "Name is required.";
    if (!formData.email) errors.email = "Email is required.";
    if (!formData.phone) errors.phone = "Phone is required.";
    if (!selectedBrand) errors.brand = "Car Brand is required.";
    if (!selectedModel) errors.model = "Car Model is required.";
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormError(errors);
      return;
    }

    const leadData = {
      company: 'Your Company',
      first_name: formData.name,
      last_name: 'Unknown',
      email: formData.email,
      phone: formData.phone,
      brand: 'Your Brand',
      Website: `https://falcons-luxury.com/InquiryForm/${selectedBrand}/${selectedModel}/${formData.message}`,
    };

    try {
      const response = await fetch("https://falconsgt.com/zoho_api.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(leadData),
      });

      if (!response.ok) {
        throw new Error("Error submitting the form");
      }

      const data = await response.json();
      console.log("Response:", data);
      setFormData(initialFormState);
      setSelectedBrand("");
      setSelectedModel("");
      setFormError({});
      setOpen(false);

      // Show confirmation message
      setSubmissionStatus({
        message: "Form submitted successfully!",
        success: true,
      });
      setShowConfirmation(true);
    } catch (error) {
      console.error("Error:", error);
      setSubmissionStatus({
        message: "Error submitting the form. Please try again.",
        success: false,
      });
      setShowConfirmation(true);
    }
  };

  return (
    <>
      {/* Floating Button */}
      <div className="floating-button-container" onClick={handleClickOpen}>
        <div className="text-bubble">
          <p className="bubble-title">Hi There!</p>
          <p className="bubble-subtitle">Inquiry Now About Any Car</p>
        </div>
        <div className="floating-button">💭</div>
      </div>

      {/* Popup Dialog */}
      <Dialog open={open} onClose={handleClose} PaperProps={{ className: "custom-dialog" }}>
        <DialogTitle className="custom-title">Inquiry Form</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} className="form-container">
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              error={!!formError.name}
              helperText={formError.name}
              required
            />
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              error={!!formError.email}
              helperText={formError.email}
              required
            />
            <TextField
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              variant="outlined"
              error={!!formError.phone}
              helperText={formError.phone}
              required
            />
            <TextField
              select
              label="Car Brand"
              value={selectedBrand}
              onChange={handleBrandChange}
              fullWidth
              margin="normal"
              variant="outlined"
              error={!!formError.brand}
              helperText={formError.brand}
              required
            >
              {Object.keys(brands).map((brand) => (
                <MenuItem key={brand} value={brand}>
                  {brand}
                </MenuItem>
              ))}
            </TextField>
            {selectedBrand && (
              <TextField
                select
                label="Car Model"
                value={selectedModel}
                onChange={handleModelChange}
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!formError.model}
                helperText={formError.model}
                required
              >
                {brands[selectedBrand].map((model) => (
                  <MenuItem key={model} value={model}>
                    {model}
                  </MenuItem>
                ))}
              </TextField>
            )}
            <TextField
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              variant="outlined"
              required
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              style={{
                backgroundColor: "#b6862d",
                color: "#e6e7e8",
                marginTop: "16px",
              }}
            >
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>

      {/* Confirmation Message */}
      {showConfirmation && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#383938",
            padding: "20px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            textAlign: "center",
            color: "#fff",
          }}
        >
          <p>{submissionStatus.message}</p>
          <button
            style={{
              marginTop: "10px",
              padding: "8px 16px",
              backgroundColor: "#b6862d",
              color: "#fff",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => navigate("/ThankYouPage")}
          >
            Close
          </button>
        </div>
      )}
      <style>
        {`
          @font-face {
            font-family: 'Everett';
          }

          .floating-button-container {
            position: fixed;
            bottom: 20px;
            right: 20px;
            z-index: 1000;
            display: flex;
            align-items: center;
            animation: slideIn 1s ease-out;
          }

          .floating-button {
            width: 60px;
            height: 60px;
            background-color: #B6862D;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            color: #e6e7e8;
            box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
            cursor: pointer;
            margin-left: 15px;
          }

          /* On Hover for Floating Button */
.floating-button:hover {
  transform: scale(1.1);
}

            .text-bubble {
              background: #ffffff;
              border-radius: 10px;
              box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
              padding: 10px 15px;
              min-width: 200px;
              text-align: left;
              color: #292929;
              font-family: "Everett", sans-serif;
            }

            .bubble-title {
              font-weight: bold;
              font-size: 14px;
              margin: 0;
            }

            .bubble-subtitle {
              font-size: 12px;
              margin: 5px 0 0;
            }


         /* Keyframe Animations */

/* Slide In for Container */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Bounce for Button */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* Fade In for Bubble */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

          .custom-dialog {
            font-family: 'Everett', sans-serif;
            background-color: #383938;
            color: #e6e7e8;
            width: 50%;
          }

          .custom-title {
            font-family: 'Everett', sans-serif;
            color: #cd9834;
          }

          .form-container {
            display: flex;
            flex-direction: column;
            gap: 16px;
          }

          .MuiOutlinedInput-root {
            background-color: #292929;
            color: #e6e7e8;
          }

          .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
            border-color: #b6862d;
          }

          .MuiInputLabel-root {
            color: #666366;
          }

          .MuiInputLabel-root.Mui-focused {
            color: #b6862d;
          }

          .MuiOutlinedInput-notchedOutline {
            border-color: #666366;
          }

          .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #b6862d;
          }
        `}
      </style>
    </>
  );
};

export default FloatingInquiryButton;
