import React from 'react';
import Slider from '../components/Slider';
import TopOffers from '../components/TopOffers';
import GallerySection from '../components/GallerySection';
import StatisticsSection from '../components/StatisticsSection';
import WhyUsSection from '../components/WhyUsSection';
import SocialMediaSection from '../components/SocialMediaSection';
import Footer from '../components/Footer';
import CarSearch  from '../components/CarSearch';
import VehicleExploreSection  from '../components/VehicleExploreSection';
import SliderComponent  from '../components/SliderComponent';
import CarGrid  from '../components/CarGrid';
import CarSearchBar  from '../components/CarSearchBar';
import SocialMediaIcons   from '../components/SocialMediaIcons';

const Home = () => {
  return (
    <div className="home-page">
      <Slider />
      {/* <CarSearch  /> */}
      <CarSearchBar  />
      <TopOffers />
      <GallerySection />
      <StatisticsSection />
      <WhyUsSection />
      {/* <SocialMediaSection /> */}
      <SocialMediaIcons  />
      {/* <VehicleExploreSection /> */}
      {/* <CarGrid /> */}
      {/* <SliderComponent /> */}
      <Footer />
    </div>
  );
}

export default Home;
