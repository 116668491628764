import React from 'react';
import '../styles/WhyUsSection.css'; // Import your CSS file
import { useTranslation } from 'react-i18next';
const WhyUsSection = () => {
  const { t } = useTranslation();
  return (
    <section className="why-us-section">
      <div className="why-us-content">
        {/* <h2 className="why-us-title">WHY US?</h2> */}
        <div className="reflect-container">
          <p className="reflect-title" content={t('WHY_US_TITLE')}>{t('WHY_US_TITLE')}</p>
        </div>
        <p className="why-us-text">
        {t('WHY_US_TEXT')}
        </p>
      </div>
    </section>
  );
};

export default WhyUsSection;
