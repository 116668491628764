import React, { useState, useEffect } from 'react';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, Slider } from '@mui/material';
import CarCard from '../components/CarCard'; // Import your CarCard component
import bannerImage from '../assets/images/pexels-gustavo-fring-4173197.jpg'; // Import your banner image
import '../styles/VehiclesPage.css'; // Import the CSS file
import Footer from '../components/Footer'; // Import your footer component
import { useLocation, useParams } from 'react-router-dom';
import { FaTachometerAlt, FaCogs, FaBatteryHalf } from "react-icons/fa";
import NewCard from '../components/NewCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';  // Base CSS for Swiper
import 'swiper/css/navigation';  // Navigation CSS
import 'swiper/css/pagination';  // Pagination CSS
import CircularProgress from '@mui/material/CircularProgress'; // MUI loader component
import { Autoplay } from "swiper/modules";
import { Navigation, Pagination } from 'swiper/modules';
import CarSearchBar from '../components/CarSearchBar';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/Falcons Lux Logo.png';
import { useTheme } from '@mui/material/styles';
const VehiclesSearchPage = () => {
  const { t } = useTranslation();
  const [results, setResults] = useState([]);
  const location = useLocation();

  const [cars, setCars] = useState([]); 
  const [filteredCars, setFilteredCars] = useState([]); // For displaying filtered cars
  const [loading, setLoading] = useState(false);
// Function to add watermark to images
const addWatermarkToImage = async (imageUrl, watermarkUrl) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Load the car image
  const carImage = new Image();
  carImage.crossOrigin = "anonymous"; // Allow cross-origin images
  carImage.src = imageUrl;

  // Load the watermark image
  const watermarkImage = new Image();
  watermarkImage.crossOrigin = "anonymous"; // Allow cross-origin images
  watermarkImage.src = watermarkUrl;

  return new Promise((resolve, reject) => {
    carImage.onload = () => {
      // Set canvas dimensions to car image dimensions
      canvas.width = carImage.width;
      canvas.height = carImage.height;

      // Draw the car image onto the canvas
      ctx.drawImage(carImage, 0, 0);

      // Set watermark dimensions
      const watermarkWidth = carImage.width * 0.3; // 30% of the car image width
      const watermarkHeight =
        (watermarkImage.height / watermarkImage.width) * watermarkWidth;

      // Calculate position for center alignment
      const centerX = (carImage.width - watermarkWidth) / 2;
      const centerY = (carImage.height - watermarkHeight) / 2;

      // Draw the watermark image in the center
      ctx.globalAlpha = 0.3; // Set watermark transparency
      ctx.drawImage(
        watermarkImage,
        centerX,
        centerY,
        watermarkWidth,
        watermarkHeight
      );

      // Resolve with the watermarked image as a data URL
      resolve(canvas.toDataURL());
    };

    carImage.onerror = () => reject("Error loading car image");
    watermarkImage.onerror = () => reject("Error loading watermark image");
  });
};  
async function fetchCars() {
  try {
    const queryParams = new URLSearchParams(location.search);
    let apiUrl = 'https://app.falconsgt.com/api/Acars?';

    // Add each query parameter from the URL to the API URL
    queryParams.forEach((value, key) => {
      apiUrl += `${key}=${value}&`;
    });
    
    const response = await fetch(apiUrl.slice(0, -1), {
      method: 'GET',
      mode: 'cors', // Use this mode to handle CORS
    }); // Replace with actual API URL
    const data = await response.json();

    if (data.success && data.cars?.data) {
      const cars = await Promise.all(data.cars.data.map(async (car) => {
        // Fetch and watermark the images
        const images = car.images?.data?.slice(0, 3).map(async (image) => {
          const carImageUrl = image.image_2;

          try {
            // Call the watermark function, passing the car image URL and the watermark URL
            const watermarkedImage = await addWatermarkToImage(
              carImageUrl, // The car image URL
              logo // The watermark image URL (replace with actual watermark path)
            );
            return watermarkedImage; // Return the watermarked image URL
          } catch (error) {
            console.error('Error adding watermark:', error);
            return carImageUrl; // Return the original image URL if watermarking fails
          }
        }) || [];

        // Return the car object with watermarked images
        return {
          carid: car.id,
          carName: `${car.maker?.data?.name || ''} ${car.model?.name || ''}`,
          description: `${car.title || ''} ${car.code || ''}`,
          specs: [
            { icon: <FaTachometerAlt />, value: `V${car.cylinders || ''}` },
            { icon: <FaCogs />, value: car.transmission || '' },
            { icon: <FaBatteryHalf />, value: car.fuel || '' },
          ],
          price: car.type || '',
          location: car.location || '',
          images: await Promise.all(images), // Wait for all images to be watermarked
          isNew: car.model_year === '2024' || car.model_year === '2025', // Example condition for 'isNew'
          fuel: car.fuel || '',
          transmission: car.transmission || '',
          brand: car.maker?.data?.name || '',
          cylinders: car.cylinders || '',
        };
      }));

      setCars(cars);
      setFilteredCars(cars); // Set both cars and filteredCars initially
      setLoading(false);
    } else {
      console.error('No car data found.');
      setLoading(false);
    }
  } catch (error) {
    console.error('Error fetching cars:', error);
    setLoading(false);
  }
}


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    // const vehicleName = queryParams.get('name');

    // if (vehicleName) {
    setLoading(true);
    fetchCars();
    // }
  }, [location.search]); // Re-run this effect whenever the search query changes

  const [fuel, setFuel] = React.useState('');
  const [transmission, setTransmission] = React.useState('');
  const [brand, setBrand] = React.useState('');
  const [cylinders, setCylinders] = React.useState([2, 12]);

  const handleFuelChange = (event) => {
    setFuel(event.target.value);
  };

  const handleTransmissionChange = (event) => {
    setTransmission(event.target.value);
  };

  const handleBrandChange = (event) => {
    setBrand(event.target.value);
  };

  const handleCylindersChange = (event, newValue) => {
    setCylinders(newValue);
  };

  // Filter cars whenever a filter value changes
  useEffect(() => {
    const filtered = cars.filter((car) => {
      const matchesFuel = fuel ? car.fuel.toLowerCase() === fuel.toLowerCase() : true;
      const matchesTransmission = transmission ? car.transmission.toLowerCase() === transmission.toLowerCase() : true;
      const matchesBrand = brand ? car.brand.toLowerCase() === brand.toLowerCase() : true;
      const matchesCylinders = car.cylinders >= cylinders[0] && car.cylinders <= cylinders[1];
      return matchesFuel && matchesTransmission && matchesBrand && matchesCylinders;
    });

    setFilteredCars(filtered);
  }, [fuel, transmission, brand, cylinders, cars]);
  return (
    <Box className="vehicles-page">
      {/* Banner */}
      <section className="hero-section-vehicles">
        <h1>{t('OUR_VEHICLES')}</h1>
        <p>{t('EXPLORE_COLLECTION')}</p>
      </section>
      {/* Grid of Car Cards */}
      {/* <Typography variant="h5" className="car-model-title_v">
        Car Models
      </Typography> */}
      <CarSearchBar />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          backgroundColor: '#000',
          padding: '20px 0',
          flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on small screens, horizontally on medium and larger
        }}
      >
        {/* Filter Section on the Left */}
        <Box
          sx={{
            width: { xs: '100%', md: '30%' }, // Full width on small screens, 30% on medium and larger
            backgroundColor: '#1a1a1a',
            color: '#fff',
            padding: '20px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            marginBottom: { xs: '20px', md: '0' }, // Add margin-bottom on small screens
          }}
        >
          <Typography variant="h6" gutterBottom style={{ fontFamily: 'everett', }}>
            {t('FILTER_OPTIONS')}
          </Typography>

          {/* Fuel Type */}
          <FormControl fullWidth variant="outlined" style={{ fontFamily: 'everett', }} sx={{ backgroundColor: '#2b2b2b', borderRadius: '4px' }}>
            <InputLabel style={{ fontFamily: 'everett', }} sx={{
              color: 'gray', // Default placeholder color
              '&.Mui-focused': {
                color: '#B6862D', // Placeholder color when focused
              },
            }}>{t('FUEL_TYPE')}</InputLabel>
            <Select value={fuel} onChange={handleFuelChange} label={t('FUEL_TYPE')} sx={{
              // Focused border color
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#B6862D',
              },
              '& .MuiSelect-select': {
                color: fuel ? '#B6862D' : 'gray', // Default or selected value color
              },
            }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    // Styles for dropdown menu
                    backgroundColor: 'white',
                    '& .MuiMenuItem-root': {
                      // Default style for items
                      color: 'black',
                    },
                    '& .MuiMenuItem-root.Mui-selected': {
                      // Selected item style
                      backgroundColor: '#B6862D',
                      color: 'white',
                    },
                    '& .MuiMenuItem-root.Mui-selected:hover': {
                      // Hover over selected item
                      backgroundColor: '#CD9834',
                    },
                  },
                },
              }}>
              <MenuItem value="Petrol">{t('PETROL')}</MenuItem>
              <MenuItem value="Diesel">{t('DIESEL')}</MenuItem>
              <MenuItem value="Electric">{t('ELECTRIC')}</MenuItem>
              <MenuItem value="Hybrid">{t('HYBRID')}</MenuItem>
            </Select>
          </FormControl>

          {/* Transmission */}
          <FormControl fullWidth variant="outlined" style={{ fontFamily: 'everett', }} sx={{ backgroundColor: '#2b2b2b', borderRadius: '4px' }}>
            <InputLabel style={{ fontFamily: 'everett', }} sx={{
              color: 'gray', // Default placeholder color
              '&.Mui-focused': {
                color: '#B6862D', // Placeholder color when focused
              },
            }}>{t('TRANSMISSION')}</InputLabel>
            <Select value={transmission} onChange={handleTransmissionChange} label="Transmission" sx={{
              // Focused border color
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#B6862D',
              },
              '& .MuiSelect-select': {
                color: fuel ? '#B6862D' : 'gray', // Default or selected value color
              },
            }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    // Styles for dropdown menu
                    backgroundColor: 'white',
                    '& .MuiMenuItem-root': {
                      // Default style for items
                      color: 'black',
                    },
                    '& .MuiMenuItem-root.Mui-selected': {
                      // Selected item style
                      backgroundColor: '#B6862D',
                      color: 'white',
                    },
                    '& .MuiMenuItem-root.Mui-selected:hover': {
                      // Hover over selected item
                      backgroundColor: '#CD9834',
                    },
                  },
                },
              }}>
              <MenuItem value="automatic">{t('AUTOMATIC')}</MenuItem>
              <MenuItem value="manual">{t('MANUAL')}</MenuItem>
            </Select>
          </FormControl>

          {/* Brand */}
          <FormControl fullWidth variant="outlined" style={{ fontFamily: 'everett', }} sx={{ backgroundColor: '#2b2b2b', borderRadius: '4px' }}>
            <InputLabel style={{ fontFamily: 'everett', }} sx={{
              color: 'gray', // Default placeholder color
              '&.Mui-focused': {
                color: '#B6862D', // Placeholder color when focused
              },
            }}>{t('BRAND')}</InputLabel>
            <Select value={brand} onChange={handleBrandChange} label="Brand" style={{ fontFamily: 'everett', }} sx={{
              // Focused border color
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#B6862D',
              },
              '& .MuiSelect-select': {
                color: fuel ? '#B6862D' : 'gray', // Default or selected value color
              },
            }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    // Styles for dropdown menu
                    backgroundColor: 'white',
                    '& .MuiMenuItem-root': {
                      // Default style for items
                      color: 'black',
                    },
                    '& .MuiMenuItem-root.Mui-selected': {
                      // Selected item style
                      backgroundColor: '#B6862D',
                      color: 'white',
                    },
                    '& .MuiMenuItem-root.Mui-selected:hover': {
                      // Hover over selected item
                      backgroundColor: '#CD9834',
                    },
                  },
                },
              }}>
              <MenuItem value="Lexus">Lexus</MenuItem>
              <MenuItem value="Mercedes-Benz">Mercedes-Benz</MenuItem>
              <MenuItem value="Audi">Audi</MenuItem>
              <MenuItem value="Land Rover">Land Rover</MenuItem>
              {/* Add more brands as needed */}
            </Select>
          </FormControl>

          {/* Cylinders (Slider) */}
          <Box sx={{ width: '100%', color: '#CD9834' }}>
            <Typography gutterBottom style={{ fontFamily: 'everett', }}>{t('CYLINDER')}</Typography>
            <Slider
              value={cylinders}
              onChange={handleCylindersChange}
              valueLabelDisplay="auto"
              min={4}
              max={8}
              step={2}
              sx={{
                '& .MuiSlider-thumb': {
                  color: '#CD9834',
                },
                '& .MuiSlider-track': {
                  color: '#CD9834',
                },
                '& .MuiSlider-rail': {
                  color: '#555',
                },
              }}
            />
          </Box>

          {/* Luxury Checkbox */}
          {/* <FormControlLabel
            control={<Checkbox sx={{ color: '#CD9834' }} />}
            label="Luxury Only"
            sx={{ color: '#fff' }}
          /> */}

          {/* Active Checkbox */}
          {/* <FormControlLabel
            control={<Checkbox sx={{ color: '#CD9834' }} />}
            label="Show Active Only"
            sx={{ color: '#fff' }}
          /> */}
        </Box>
        {/* Vehicles Section on the Right */}
        <Box
          sx={{
            width: { xs: '100%', md: '70%' }, // Full width on small screens, 70% on medium and larger
          }}
        >
          <div className="title-container">
            <h2 className="title-text" style={{ fontFamily: 'everett', }}>{t('BROWSE_RECENT_VEHICLES')}</h2>
          </div>
          {/* <Box
          sx={{
            maxWidth: "80%",
            margin: "auto",
          }}
        > */}
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
              <CircularProgress sx={{ color: '#CD9834' }} />
            </Box>
          ) : (
            <div className="cars-grid">
              {filteredCars.map((car) => (
                <div className="grid-item" key={car.carid}>
                  <NewCard {...car} />
                </div>
              ))}
            </div>
          )}
        </Box>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default VehiclesSearchPage;
