import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';

// import { Navigation, Pagination } from "swiper";
import { Navigation, Pagination, EffectCoverflow } from "swiper/modules"; // Swiper 9+ modules
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import img1 from '../assets/images/GalleryImages/G63.jpg';
import img2 from '../assets/images/GalleryImages/Defender.jpg';
import img3 from '../assets/images/GalleryImages/AudiQ8.jpg';
import img4 from '../assets/images/GalleryImages/Lexus.jpg';
import img5 from '../assets/images/GalleryImages/Maybach.jpg';
import img6 from '../assets/images/GalleryImages/MBClass.jpg';
import img7 from '../assets/images/GalleryImages/MBEQS.jpg';
import img8 from '../assets/images/GalleryImages/RangeRover.jpg';
import '../styles/GallerySection.css';
import { useTranslation } from 'react-i18next';
const GallerySection = () => {
  const { t, i18n } = useTranslation();
  const [swiperKey, setSwiperKey] = useState(0); // Unique key to force re-render
  const isRtl = i18n.language === 'ar'; // Check if the language is RTL

  useEffect(() => {
    // Update the key whenever the language changes
    setSwiperKey((prevKey) => prevKey + 1);
  }, [i18n.language]);
  return (
    <section className="gallery-section-h">
      <div className="title-container">
        <h2 className="title-text">{t('GALLERY_TITLE')}</h2>
      </div>
      <div className="gallery-slider-h">
        <Swiper
          key={swiperKey} 
          className="my-focused-slider" 
          modules={[Navigation, Pagination, EffectCoverflow]}
          navigation
          // pagination={{ clickable: true }}
          // effect="coverflow"
          coverflowEffect={{
            rotate: 40, // Increase rotation for a more dramatic effect
            stretch: 100, // Increase stretch to make the centered image larger
            depth: 200, // Increase depth for more perspective
            modifier: 1, // Default modifier
            slideShadows: true, // Keep slide shadows for depth perception
          }}
          slidesPerView={1.8}
          centeredSlides
          // style={{ height: "800px"}}
          breakpoints={{
            1024: {
              slidesPerView: 1.8, // 3 slides per view on desktop
            },
            768: {
              slidesPerView: 1.8, // 2 slides per view on tablet
            },
            480: {
              slidesPerView: 1, // 1 slide per view on mobile
            },
          }}
        >
          <SwiperSlide
            style={{
              backgroundImage: `url(${img1})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              height: '100%',
              width:"100%",
              backgroundRepeat: 'no-repeat',
              // border: '1px solid #CD9834',
            }}
          >
          </SwiperSlide>
          <SwiperSlide
            style={{
              backgroundImage: `url(${img2})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              height: '100%',
              width:"100%",
              backgroundRepeat: 'no-repeat'
            }}
          >

          </SwiperSlide>
          <SwiperSlide
            style={{
              backgroundImage: `url(${img3})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              height: '100%',
              width:"100%",
              backgroundRepeat: 'no-repeat'
            }}
          >

          </SwiperSlide>
          <SwiperSlide
            style={{
              backgroundImage: `url(${img4})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              height: '100%',
              width:"100%",
              backgroundRepeat: 'no-repeat'
            }}
          >

          </SwiperSlide>
          <SwiperSlide
            style={{
              backgroundImage: `url(${img5})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              height: '100%',
              width:"100%",
              backgroundRepeat: 'no-repeat'
            }}
          >

          </SwiperSlide>
          <SwiperSlide
            style={{
              backgroundImage: `url(${img6})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              height: '100%',
              width:"100%",
              backgroundRepeat: 'no-repeat'
            }}
          >

          </SwiperSlide>
          <SwiperSlide
            style={{
              backgroundImage: `url(${img7})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              height: '100%',
              width:"100%",
              backgroundRepeat: 'no-repeat'
            }}
          >

          </SwiperSlide>
          <SwiperSlide
            style={{
              backgroundImage: `url(${img8})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              height: '100%',
              width:"100%",
              backgroundRepeat: 'no-repeat'
            }}
          >
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
};

export default GallerySection;
