import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const TermsandConditions = () => {
  const [open, setOpen] = useState(true); // Dialog is open by default
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('terms_title')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
        {t('terms_intro')}
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
        {t('terms_text1')}
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
        {t('terms_text2')}
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
        {t('terms_text3')}
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
        {t('terms_text4')}
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
        {t('terms_text5')}
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
        {t('terms_text6')}
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
        {t('terms_text7')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" style={{ backgroundColor: "#CD9834" }}>
        {t('accept')}
        </Button>
        {/* <Button onClick={handleClose}  variant="outlined" style={{ backgroundColor:"red" }}>
          Decline
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default TermsandConditions;
