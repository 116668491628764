import React, { useState } from 'react';
import '../styles/Footer.css'; // Assuming you have the CSS in a separate file
import logo from '../assets/images/Falcons Lux Logo H.png';
import { Link } from 'react-router-dom';
import CookiePolicyDialog from './CookiePolicyDialog'; // Import the dialog component
import TermsandConditions from './TermsandConditions'; // Import the dialog component
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const [isCookieDialogOpen, setIsCookieDialogOpen] = useState(false);
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false); // State for terms and conditions dialog

  const handleOpenCookieDialog = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    setIsCookieDialogOpen(true);
  };

  const handleOpenTermsDialog = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    setIsTermsDialogOpen(true); // Open the Terms and Conditions dialog
  };
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h3>{t('QUICK_LINKS')}</h3>
          <ul>
            <li><Link to="/home">{t('HOME')}</Link></li>
            <li><Link to="/vehicles-page?name=All%20Vehicles">{t('BROWSE_VEHICLES')}</Link></li>
            <li><Link to="/top-offers">{t('CURRENT_VEHICLE_OFFERS')}</Link></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>{t('CONTACT_US')}</h3>
          <ul>
            <li><a>{t('ADDRESS')}: {t('SHOWROOM_ADDRESS')}</a></li>
            <li><a>{t('PHONE')}: +971 (56) 414-5000</a></li>
            <li><a>{t('EMAIL')}: info@falcons-luxury.com</a></li>
            <li><a>{t('HOURS')}: {t('OPEN_DAILY')}</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>{t('ABOUT_US')}</h3>
          <ul>
            <li><a>{t('ABOUT_DESCRIPTION')}</a></li>
          </ul>
        </div>
        <div className="footer-logo">
          <img src={logo} alt={t('LOGO_ALT_TEXT')} />
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {t('COPYRIGHT')} 2024. {t('ALL_RIGHTS_RESERVED')}</p>
        <ul className="footer-links">
          <li><Link to="#" onClick={handleOpenTermsDialog}>{t('TERMS_CONDITIONS')}</Link></li>
          <li><Link to="#" onClick={handleOpenCookieDialog}>{t('COOKIE_POLICY')}</Link></li>
        </ul>
      </div>

      {isCookieDialogOpen && (
        <CookiePolicyDialog onClose={() => setIsCookieDialogOpen(false)} />
      )}

      {isTermsDialogOpen && (
        <TermsandConditions onClose={() => setIsTermsDialogOpen(false)} />
      )}
    </footer>
  );
};

export default Footer;
