import React, { useState, useEffect } from 'react';
import { Box, Typography, Select, MenuItem, FormControl, InputLabel, Checkbox, FormControlLabel, Slider } from '@mui/material';
import CarCard from '../components/CarCard'; // Import your CarCard component
import bannerImage from '../assets/images/pexels-gustavo-fring-4173197.jpg'; // Import your banner image
import '../styles/VehiclesPage.css'; // Import the CSS file
import Footer from '../components/Footer'; // Import your footer component
import { useLocation, useParams } from 'react-router-dom';
import { FaTachometerAlt, FaCogs, FaBatteryHalf } from "react-icons/fa";
import NewCard from '../components/NewCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';  // Base CSS for Swiper
import 'swiper/css/navigation';  // Navigation CSS
import 'swiper/css/pagination';  // Pagination CSS
import CircularProgress from '@mui/material/CircularProgress'; // MUI loader component
import { Autoplay } from "swiper/modules";
import { Navigation, Pagination } from 'swiper/modules';
import CarSearchBar from '../components/CarSearchBar';
import { useTranslation } from 'react-i18next';
import logo from '../assets/images/Falcons Lux Logo.png';
import { useTheme } from '@mui/material/styles';
const VehiclesModelPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const vehicleName = queryParams.get('name');
  const [cars, setCars] = useState([]); // Initialize with an empty array
  const [filteredCars, setFilteredCars] = useState([]); // For displaying filtered cars
  const [loading, setLoading] = useState(true);
  const [carMaker, setCarMaker] = useState(''); // State variable for car maker
  // Function to add watermark to images
  const addWatermarkToImage = async (imageUrl, watermarkUrl) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
  
    // Load the car image
    const carImage = new Image();
    carImage.crossOrigin = "anonymous"; // Allow cross-origin images
    carImage.src = imageUrl;
  
    // Load the watermark image
    const watermarkImage = new Image();
    watermarkImage.crossOrigin = "anonymous"; // Allow cross-origin images
    watermarkImage.src = watermarkUrl;
  
    return new Promise((resolve, reject) => {
      carImage.onload = () => {
        // Set canvas dimensions to car image dimensions
        canvas.width = carImage.width;
        canvas.height = carImage.height;
  
        // Draw the car image onto the canvas
        ctx.drawImage(carImage, 0, 0);
  
        // Set watermark dimensions
        const watermarkWidth = carImage.width * 0.3; // 30% of the car image width
        const watermarkHeight =
          (watermarkImage.height / watermarkImage.width) * watermarkWidth;
  
        // Calculate position for center alignment
        const centerX = (carImage.width - watermarkWidth) / 2;
        const centerY = (carImage.height - watermarkHeight) / 2;
  
        // Draw the watermark image in the center
        ctx.globalAlpha = 0.3; // Set watermark transparency
        ctx.drawImage(
          watermarkImage,
          centerX,
          centerY,
          watermarkWidth,
          watermarkHeight
        );
  
        // Resolve with the watermarked image as a data URL
        resolve(canvas.toDataURL());
      };
  
      carImage.onerror = () => reject("Error loading car image");
      watermarkImage.onerror = () => reject("Error loading watermark image");
    });
  };  
  async function fetchCars() {
    try {
      const response = await fetch(`https://app.falconsgt.com/api/AgetCarByMaker/${vehicleName}`, {
        method: 'GET',
        mode: 'cors', // Use this mode to handle CORS
      }); // Replace with actual API URL
      const data = await response.json();
  
      if (data.success && data.cars?.data) {
        const makerName = data.cars.data[0].maker?.data?.name;
        setCarMaker(makerName || '');
        const cars = data.cars.data.map(async (car) => {
          // Fetch and watermark the images
          const images = car.images?.data?.slice(0, 3).map(async (image) => {
            const carImageUrl = image.image_2;
  
            try {
              // Call the watermark function, passing the car image URL and the watermark URL
              const watermarkedImage = await addWatermarkToImage(
                carImageUrl, // The car image URL
                logo // The watermark image URL (replace with actual watermark path)
              );
              return watermarkedImage; // Return the watermarked image URL
            } catch (error) {
              console.error('Error adding watermark:', error);
              return carImageUrl; // Return the original image URL if watermarking fails
            }
          }) || [];
  
          return {
            carid: car.id,
            carName: `${car.maker?.data?.name || ''} ${car.model?.name || ''}`,
            description: `${car.title || ''} ${car.code || ''}`,
            specs: [
              { icon: <FaTachometerAlt />, value: `V${car.cylinders || ''}` },
              { icon: <FaCogs />, value: car.transmission || '' },
              { icon: <FaBatteryHalf />, value: car.fuel || '' },
            ],
            price: car.type || '',
            location: car.location || '',
            images: await Promise.all(images), // Wait for all images to be watermarked
            isNew: car.model_year === '2024' || car.model_year === '2025',
            fuel: car.fuel || '',
            transmission: car.transmission || '',
            type: car.type || '',
            cylinders: car.cylinders || '',
          };
        });
  
        // Wait for all car objects to be processed
        const resolvedCars = await Promise.all(cars);
        
        setCars(resolvedCars); // Update state directly here
        setFilteredCars(resolvedCars); // Set both cars and filteredCars initially
        setLoading(false);
      } else {
        console.error('No car data found.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching cars:', error);
      setLoading(false);
    }
  }
  

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const vehicleName = queryParams.get('name');

    if (vehicleName) {
      setLoading(true);
      fetchCars(vehicleName);
    }
  }, [location.search]); // Re-run this effect whenever the search query changes


  const [fuel, setFuel] = React.useState('');
  const [transmission, setTransmission] = React.useState('');
  const [type, setType] = React.useState('');
  const [cylinders, setCylinders] = React.useState([2, 12]);

  const handleFuelChange = (event) => {
    setFuel(event.target.value);
  };

  const handleTransmissionChange = (event) => {
    setTransmission(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleCylindersChange = (event, newValue) => {
    setCylinders(newValue);
  };

  // Filter cars whenever a filter value changes
  useEffect(() => {
    const filtered = cars.filter((car) => {
      const matchesFuel = fuel ? car.fuel.toLowerCase() === fuel.toLowerCase() : true;
      const matchesTransmission = transmission ? car.transmission.toLowerCase() === transmission.toLowerCase() : true;
      const matchesType = type ? car.type.toLowerCase() === type.toLowerCase() : true;
      const matchesCylinders = car.cylinders >= cylinders[0] && car.cylinders <= cylinders[1];
      return matchesFuel && matchesTransmission && matchesType && matchesCylinders;
    });

    setFilteredCars(filtered);
  }, [fuel, transmission, cylinders, type, cars]);
  return (
    <Box className="vehicles-page">
      {/* Banner */}
      <section className="hero-section-vehicles">
        <h1>{t('OUR_VEHICLES')}</h1>
        <p>{t('EXPLORE_COLLECTION')}</p>
      </section>
      {/* Grid of Car Cards */}
      {/* <Typography variant="h5" className="car-model-title_v">
        Car Models
      </Typography> */}
      <CarSearchBar />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          backgroundColor: '#000',
          padding: '20px 0',
          flexDirection: { xs: 'column', md: 'row' }, // Stack vertically on small screens, horizontally on medium and larger
        }}
      >
        {/* Filter Section on the Left */}
        <Box
          sx={{
            width: { xs: '100%', md: '30%' }, // Full width on small screens, 30% on medium and larger
            backgroundColor: '#1a1a1a',
            color: '#fff',
            padding: '20px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            marginBottom: { xs: '20px', md: '0' }, // Add margin-bottom on small screens
          }}
        >
          <Typography variant="h6" gutterBottom style={{ fontFamily: 'everett' }}>
            {t('FILTER_OPTIONS')}
          </Typography>

          {/* Fuel Type */}
          <FormControl fullWidth variant="outlined" sx={{ backgroundColor: '#2b2b2b', borderRadius: '4px', fontFamily: 'everett' }}>
            <InputLabel sx={{ color: 'gray', '&.Mui-focused': { color: '#B6862D' } }} style={{ fontFamily: 'everett' }}>
              {t('FUEL_TYPE')}
            </InputLabel>
            <Select
              value={fuel}
              onChange={handleFuelChange}
              label={t('FUEL_TYPE')}
              style={{ fontFamily: 'everett' }}
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#B6862D',
                },
                '& .MuiSelect-select': {
                  color: fuel ? '#B6862D' : 'gray',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: 'white',
                    '& .MuiMenuItem-root': {
                      color: 'black',
                    },
                    '& .MuiMenuItem-root.Mui-selected': {
                      backgroundColor: '#B6862D',
                      color: 'white',
                    },
                    '& .MuiMenuItem-root.Mui-selected:hover': {
                      backgroundColor: '#CD9834',
                    },
                  },
                },
              }}
            >
              <MenuItem value="Petrol">{t('PETROL')}</MenuItem>
              <MenuItem value="Diesel">{t('DIESEL')}</MenuItem>
              <MenuItem value="Electric">{t('ELECTRIC')}</MenuItem>
              <MenuItem value="Hybrid">{t('HYBRID')}</MenuItem>
            </Select>
          </FormControl>

          {/* Transmission */}
          <FormControl fullWidth variant="outlined" sx={{ backgroundColor: '#2b2b2b', borderRadius: '4px' }}>
            <InputLabel style={{ fontFamily: 'everett' }} sx={{ color: 'gray', '&.Mui-focused': { color: '#B6862D' } }}>
              {t('TRANSMISSION')}
            </InputLabel>
            <Select
              value={transmission}
              onChange={handleTransmissionChange}
              label="Transmission"
              style={{ fontFamily: 'everett' }}
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#B6862D',
                },
                '& .MuiSelect-select': {
                  color: fuel ? '#B6862D' : 'gray',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: 'white',
                    '& .MuiMenuItem-root': {
                      color: 'black',
                    },
                    '& .MuiMenuItem-root.Mui-selected': {
                      backgroundColor: '#B6862D',
                      color: 'white',
                    },
                    '& .MuiMenuItem-root.Mui-selected:hover': {
                      backgroundColor: '#CD9834',
                    },
                  },
                },
              }}
            >
              <MenuItem value="automatic">{t('AUTOMATIC')}</MenuItem>
              <MenuItem value="manual">{t('MANUAL')}</MenuItem>
            </Select>
          </FormControl>

          {/* Type */}
          <FormControl fullWidth variant="outlined" sx={{ backgroundColor: '#2b2b2b', borderRadius: '4px' }}>
            <InputLabel style={{ fontFamily: 'everett' }} sx={{ color: 'gray', '&.Mui-focused': { color: '#B6862D' } }}>
            {t('TYPE')}
            </InputLabel>
            <Select
              value={type}
              onChange={handleTypeChange}
              label="Type"
              style={{ fontFamily: 'everett' }}
              sx={{
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#B6862D',
                },
                '& .MuiSelect-select': {
                  color: fuel ? '#B6862D' : 'gray',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: 'white',
                    '& .MuiMenuItem-root': {
                      color: 'black',
                    },
                    '& .MuiMenuItem-root.Mui-selected': {
                      backgroundColor: '#B6862D',
                      color: 'white',
                    },
                    '& .MuiMenuItem-root.Mui-selected:hover': {
                      backgroundColor: '#CD9834',
                    },
                  },
                },
              }}
            >
              <MenuItem value="suv">Suv</MenuItem>
              <MenuItem value="sedans">Sedans</MenuItem>
              <MenuItem value="coupe">Coupe</MenuItem>
            </Select>
          </FormControl>

          {/* Cylinders (Slider) */}
          <Box sx={{ width: '100%', color: '#CD9834' }}>
            <Typography gutterBottom style={{ fontFamily: 'everett' }}>
            {t('CYLINDER')}
            </Typography>
            <Slider
              value={cylinders}
              onChange={handleCylindersChange}
              valueLabelDisplay="auto"
              min={4}
              max={8}
              step={2}
              sx={{
                '& .MuiSlider-thumb': {
                  color: '#CD9834',
                },
                '& .MuiSlider-track': {
                  color: '#CD9834',
                },
                '& .MuiSlider-rail': {
                  color: '#555',
                },
              }}
            />
          </Box>
        </Box>

        {/* Vehicles Section on the Right */}
        <Box
          sx={{
            width: { xs: '100%', md: '70%' }, // Full width on small screens, 70% on medium and larger
          }}
        >
          <div className="title-container">
            <h2 className="title-text" style={{ fontFamily: 'everett' }}>
              {t('BROWSE_OUR_VEHICLES', { carMaker })}
            </h2>
          </div>

          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
              <CircularProgress sx={{ color: '#CD9834' }} />
            </Box>
          ) : (
            <div className="cars-grid">
              {filteredCars.map((car) => (
                <div className="grid-item" key={car.carid}>
                  <NewCard {...car} />
                </div>
              ))}
            </div>
          )}
        </Box>
      </Box>


      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default VehiclesModelPage;
