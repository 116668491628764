import React, { useState, useRef } from 'react';
import '../styles/VideoGallery.css';
import video1 from "../assets/videos/14228182-hd_1920_1080_60fps.mp4";
import video8 from "../assets/images/1724849270716-blob.webp";
import video2 from "../assets/videos/6037276_Police Car_Car_1920x1080.mp4";
import video3 from "../assets/videos/6873503-uhd_2160_3840_25fps.mp4";
import video4 from "../assets/images/pexels-tony-boyd-513947829-17084778.jpg";
import video5 from "../assets/images/1724848900138-blob.webp";
import short2 from "../assets/videos/5309352-hd_1920_1080_25fps.mp4";
import short1 from "../assets/videos/5309422-hd_1920_1080_25fps.mp4";
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
const VideoGallery = () => {
  const { t } = useTranslation();
  // Sample data for videos and shorts
  const videos = [
    { id: 1, src: "https://www.youtube.com/embed/fJ68CrMaE5Y", title: "Local Video 1", description: "Description of Local Video 1", views: "1.2K views", isYouTube: true },
    { id: 2, src: "https://www.youtube.com/embed/sqKRoteqU1E", title: "Local Video 2", description: "Description of Local Video 2", views: "3.5K views", isYouTube: true },
    { id: 3, src: "https://www.youtube.com/embed/_dvas6STsUU", title: "YouTube Video 1", description: "Description of YouTube Video 1", views: "5.7K views", isYouTube: true },
    { id: 4, src: "https://www.youtube.com/embed/F7L0CpqQ_-w", title: "YouTube Video 2", description: "Description of YouTube Video 2", views: "10.4K views", isYouTube: true },
  ];


  const shorts = [
    { id: 1, src: "https://www.youtube.com/embed/cyHOO3S1jxI", description: "", isYouTube: true },
    { id: 2, src: "https://www.youtube.com/embed/gwPQvsibYMI", description: "", isYouTube: true },
    {
      id: 3,
      src: "https://www.youtube.com/embed/1jJXCS-lHMI",
      description: "",
      isYouTube: true,
    },
    {
      id: 4,
      src: "https://www.youtube.com/embed/EV0odYfUthI",
      description: "",
      isYouTube: true,
    },
  ];

  const [activeTab, setActiveTab] = useState('videos');
  const videoRefs = useRef([]); // To store references to video elements

  const handlePlayPause = (index) => {
    const video = videoRefs.current[index];
    if (video.paused) {
      video.play();
    } else {
      video.pause();
    }
  };
  const [selectedReel, setSelectedReel] = useState(null);

  const handleReelClick = (index) => {
    setSelectedReel(index);
  };

  const closeFullView = () => {
    setSelectedReel(null);
  };
  return (
    <div className="gallery-page">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>{t('OUR_GALLERY_TITLE')}</h1>
          <p>{t('GALLERY_DESCRIPTION')}</p>
        </div>
      </section>

      <div className="content">
        <div className="tabs">
          <button className={`tab ${activeTab === 'videos' ? 'active' : ''}`} onClick={() => setActiveTab('videos')}>
            {t('VIDEOS_TAB')}
          </button>
          <button className={`tab ${activeTab === 'shorts' ? 'active' : ''}`} onClick={() => setActiveTab('shorts')}>
            {t('SHORTS_TAB')}
          </button>
        </div>

        {activeTab === 'videos' && (
          <section className="video-section">
            {videos.map((video, index) => (
              <div
                key={video.id}
                className="video-card"
                onClick={() => handleReelClick(index)}
                style={{ cursor: 'pointer' }}
              >
                {video.isYouTube ? (
                  <iframe
                    width="100%"
                    height="300px" // Adjust height as needed
                    src={video.src}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <video
                    ref={(el) => (videoRefs.current[index] = el)}
                    width="100%"
                    height="300px"
                    src={video.src}
                    controls
                  />
                )}
                {/* <div className="card-content">
                  <h3>{video.title}</h3>
                  <p>{video.description}</p>
                  <div className="views">{video.views}</div>
                </div> */}
              </div>
            ))}
          </section>
        )}


        {activeTab === "shorts" && (
          <section className="shorts-section">
            {shorts.map((short, index) => (
              <div
                key={short.id}
                className="shorts-reel"
                onClick={() => handleReelClick(index)}
                style={{ cursor: "pointer" }}
              >
                {short.isYouTube ? (
                  <iframe
                    width="100%"
                    height="350px"
                    src={short.src}
                    frameBorder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <video
                    ref={(el) => (videoRefs.current[index] = el)}
                    width="100%"
                    height="350px"
                    src={short.src}
                    loop
                    muted
                    autoPlay
                  />
                )}
                <div className="short-description">{short.description}</div>
              </div>
            ))}
          </section>
        )}

        {/* Full-Width Reel or Video View */}
        {selectedReel !== null && (
          <div className="full-width-reel">
            <div className="overlay" onClick={closeFullView}></div>
            <div className="reel-content">
              {shorts[selectedReel].isYouTube ? (
                <iframe
                  width="100%"
                  height="100%"
                  src={shorts[selectedReel].src}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                ></iframe>
              ) : (
                <video
                  width="100%"
                  height="100%"
                  src={shorts[selectedReel].src}
                  controls
                  autoPlay
                />
              )}
              <button onClick={closeFullView} className="close-button">
                Close
              </button>
            </div>
          </div>
        )}
      </div>


      <Footer />
    </div>
  );
};

export default VideoGallery;
