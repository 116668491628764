import React , { useState, useEffect , useRef  } from "react";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';  // Base CSS for Swiper
import 'swiper/css/navigation';  // Navigation CSS
import 'swiper/css/pagination';  // Pagination CSS
import { FaTachometerAlt, FaCogs, FaBatteryHalf } from "react-icons/fa";
// Correct import of Swiper modules
import { Navigation, Pagination } from 'swiper/modules';
import '../styles/TopOffers.css'; // Import your CSS file
import NewCardHome from '../components/NewCardHome';
import CircularProgress from '@mui/material/CircularProgress'; // MUI loader component
import { Autoplay } from "swiper/modules";
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import logo from '../assets/images/Falcons Lux Logo.png';
const TopOffers = () => {
  const { t, i18n } = useTranslation();
  const [isRTL, setIsRTL] = useState(i18n.language === 'ar'); // Set initial direction
  const [swiperInstance, setSwiperInstance] = useState(null); // Store Swiper instance
  const [cars, setCars] = useState([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true);
  const theme = useTheme(); // Access the theme
  // Function to add watermark to images
  const addWatermarkToImage = async (imageUrl, watermarkUrl) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
  
    // Load the car image
    const carImage = new Image();
    carImage.crossOrigin = "anonymous"; // Allow cross-origin images
    carImage.src = imageUrl;
  
    // Load the watermark image
    const watermarkImage = new Image();
    watermarkImage.crossOrigin = "anonymous"; // Allow cross-origin images
    watermarkImage.src = watermarkUrl;
  
    return new Promise((resolve, reject) => {
      carImage.onload = () => {
        // Set canvas dimensions to car image dimensions
        canvas.width = carImage.width;
        canvas.height = carImage.height;
  
        // Draw the car image onto the canvas
        ctx.drawImage(carImage, 0, 0);
  
        // Set watermark dimensions
        const watermarkWidth = carImage.width * 0.3; // 30% of the car image width
        const watermarkHeight =
          (watermarkImage.height / watermarkImage.width) * watermarkWidth;
  
        // Calculate position for center alignment
        const centerX = (carImage.width - watermarkWidth) / 2;
        const centerY = (carImage.height - watermarkHeight) / 2;
  
        // Draw the watermark image in the center
        ctx.globalAlpha = 0.3; // Set watermark transparency
        ctx.drawImage(
          watermarkImage,
          centerX,
          centerY,
          watermarkWidth,
          watermarkHeight
        );
  
        // Resolve with the watermarked image as a data URL
        resolve(canvas.toDataURL());
      };
  
      carImage.onerror = () => reject("Error loading car image");
      watermarkImage.onerror = () => reject("Error loading watermark image");
    });
  };  

  async function fetchCars() {
    try {
      const response = await await fetch('https://app.falconsgt.com/api/AgetAllCars', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    });
      console.log(response);
      const data = await response.json();

      if (data.success && data.latest_cars?.data) {
        const cars = await Promise.all(
          data.latest_cars.data.map(async (car) => {
            const imagesWithWatermarks = await Promise.all(
              (car.images?.data?.slice(0, 3) || []).map(async (image) => {
                return addWatermarkToImage(
                  image.image_2,
                  logo
                );
              })
            );

            return {
              carid: car.id,
              carName: `${car.maker?.data?.name || ""} ${
                car.model?.name || ""
              }`,
              description: `${car.title || ""} ${car.code || ""}`,
              specs: [
                { icon: <FaTachometerAlt />, value: `V${car.cylinders || ""}` },
                { icon: <FaCogs />, value: car.transmission || "" },
                { icon: <FaBatteryHalf />, value: car.fuel || "" },
              ],
              price: car.type || "",
              location: car.location || "",
              images: imagesWithWatermarks,
              isNew: car.model_year === "2024" || car.model_year === "2025",
            };
          })
        );

        setCars(cars); // Update state directly here
        setLoading(false);
      } else {
        console.error('No car data found.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching cars:', error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCars();
  }, []);
  const swiperRef = useRef(null); // Reference for the Swiper instance

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.update(); // Trigger update on language change
    }
  }, [i18n.language]); // Re-run when language changes
  useEffect(() => {
    setIsRTL(i18n.language === 'ar');
  }, [i18n.language]);

  const handleSwiperInit = (swiper) => {
    setSwiperInstance(swiper);
  };
  
  useEffect(() => {
    if (swiperInstance) {
      swiperInstance.update(); // Refresh Swiper on language change
    }
  }, [i18n.language]);
  return (
    <Box
      sx={{
        width: "100%",          
        backgroundColor: "#000", 
        paddingY: 4,             
        paddingX: 4,             
      }}
    >
      <div className="title-container">
        <h2 className="title-text">{t('BROWSE_RECENT_VEHICLES')}</h2>
      </div>
      <Box
        sx={{
          maxWidth: "95%",      
          margin: "auto", 
          display: 'flex',
          justifyContent: 'center', // Center the Swiper in the parent
          alignItems: 'center',
          width: '100%',      
        }}
      >
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
            <CircularProgress sx={{ color: '#CD9834' }} />
          </Box>
        ) : (
          <Swiper
          style={{
            padding: "50px 0", // Reduced padding
            width: '100%',    // Ensure full width
            display: 'flex',  // Ensure swiper is using flexbox
            alignItems: 'center', // Vertically center the slides
          }}
          slidesPerView={4} // Default slides for large screens
          spaceBetween={0} // Add spacing between slides
          loop={true}
          pagination={{ clickable: true }}
          modules={[Autoplay, Pagination, Navigation]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: true,
            reverseDirection: isRTL, // Reverse autoplay direction for RTL
          }}
          navigation={{
            prevEl: isRTL ? '.swiper-button-next' : '.swiper-button-prev',
            nextEl: isRTL ? '.swiper-button-prev' : '.swiper-button-next',
          }}
          rtl={isRTL} // Dynamically apply RTL for Swiper
          key={isRTL ? 'rtl' : 'ltr'} // Force re-render when the direction changes
          breakpoints={{
            1024: {
              slidesPerView: 4, // Desktop: 4 slides
            },
            768: {
              slidesPerView: 2, // Tablet: 2 slides
            },
            480: {
              slidesPerView: 1, // Mobile: 1 slide
            },
          }}
        >
          {cars.map((car) => (
            <SwiperSlide key={car.carid}> {/* Use car.carid here */}
              <NewCardHome {...car} 
              images={i18n.language === 'ar' ? [...car.images].reverse() : car.images} // Reverse images for Arabic
              />
            </SwiperSlide>
          ))}
        </Swiper>
         )}
      </Box>
    </Box>
  );
};

export default TopOffers;