import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/CarBrandsSliderNew.css"
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper"; // Import Swiper Navigation module
import "swiper/css"; // Core Swiper styles
import "swiper/css/navigation"; // Navigation styles
import { Link } from 'react-router-dom';
import landRoverLogo from '../assets/images/Land Rover.png'; // Import the image
import Lexus from '../assets/images/Lexus.png'; // Import the image
import Audi from '../assets/images/Audi.png'; // Import the image
import Mercedes from '../assets/images/Mercedes benz.png'; // Import the image
const CarBrandsSliderNew = () => {
  // Array of car data
  const carBrands = [
    { name: "Mercedes", items: "1256 Items", logo: Mercedes , id: 11 },
    { name: "Land Rover", items: "1256 Items", logo: landRoverLogo , id: 15 },
    { name: "Lexus", items: "1256 Items", logo: Lexus , id: 6 },
    // { name: "Volkswagen", items: "1256 Items", logo: "https://wgl-dsites.net/genesisauto/wp-content/uploads/2024/05/client-3.webp" , id: 0 },
    // { name: "Toyota", items: "1256 Items", logo: "https://wgl-dsites.net/genesisauto/wp-content/uploads/2024/05/client-2.webp" , id: 1 },
    { name: "Audi", items: "1256 Items", logo: Audi, id: 27 },
  ];
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Adjust number of visible slides
    slidesToScroll: 1,
    centerMode: true, // Centers the slider
    // centerPadding: "60px", 
    arrows: true, // Enables left and right arrows
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
  };



  return (
    <div className="slider-container2">
      <div className="logo-slider-container">
        <Slider {...settings}>
          {carBrands.map((logo) => (
            <div key={logo.id} className="logo-item">
              <Link to={`/vehicles-model-page?name=${encodeURIComponent(logo.id)}`} className="logo-link">
              <div className="logo-content">
                <img src={logo.logo} alt={logo.name} className="logo-image" />
                {/* <div className="logo-details"> */}
                  {/* <p className="logo-name">{logo.name}</p> */}
                  {/* <p className="logo-number">{logo.number}</p> */}
                {/* </div> */}
              </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default CarBrandsSliderNew;
