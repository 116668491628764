import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
const CookiePolicyDialog = () => {
  const [open, setOpen] = useState(true); // Dialog is open by default

  const handleClose = () => {
    setOpen(false);
  };
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('cookie_policy_title')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
        {t('cookie_policy_intro')}
        </Typography>

        <Typography variant="body2" color="textSecondary" paragraph>
        {t('cookie_policy_text1')}
        </Typography>

        <Typography variant="body2" color="textSecondary" paragraph>
        {t('cookie_policy_text2')}
        </Typography>

        <Typography variant="body2" color="textSecondary" paragraph>
        {t('cookie_policy_text3')}
        </Typography>

        <Typography variant="body2" color="textSecondary" paragraph>
        {t('cookie_policy_text4')}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="contained" style={{ backgroundColor: "#CD9834" }}>
        {t('accept')}
        </Button>
        {/* <Button onClick={handleClose}  variant="outlined" style={{ backgroundColor:"red" }}>
          Decline
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default CookiePolicyDialog;
