import React, { useState, useEffect, useRef } from 'react';
import '../styles/Slider.css';
import { useTranslation } from 'react-i18next'; // Import i18n hook
const Slider = () => {
  const { i18n } = useTranslation(); // Access i18n instance
  // Array of video URLs from local assets
  const videos = [
    require('../assets/videos/paner 1.webm'),
    require('../assets/videos/Baner V2.webm'),
    require('../assets/videos/banner V3.webm'),
    // Add more video paths as needed
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0); // Track video progress
  const videoRef = useRef(null); // Reference to the video element

  useEffect(() => {
    const video = videoRef.current;

    // Update progress bar as the video plays
    const updateProgress = () => {
      const currentTime = video.currentTime;
      const duration = video.duration;
      if (duration > 0) {
        setProgress((currentTime / duration) * 100); // Update progress in percentage
      }
    };

    // Auto move to the next video when the current video ends
    const handleVideoEnd = () => {
      nextVideo();
    };

    // Add event listeners to the video element
    video.addEventListener('timeupdate', updateProgress);
    video.addEventListener('ended', handleVideoEnd);

    // Cleanup event listeners when the component unmounts or when the video changes
    return () => {
      video.removeEventListener('timeupdate', updateProgress);
      video.removeEventListener('ended', handleVideoEnd);
    };
  }, [currentIndex]);

  // Auto slide to the next video every X seconds (e.g., 10 seconds)
  // useEffect(() => {
  //   const autoSlide = setInterval(() => {
  //     nextVideo();
  //   }, 15000); // Change video every 10 seconds

  //   return () => clearInterval(autoSlide);
  // }, []);

  // Function to go to the next video
  const nextVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
    setProgress(0); // Reset progress when video changes
  };

  // Function to go to the previous video
  const prevVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
    setProgress(0); // Reset progress when video changes
  };
  const isRTL = i18n.language === 'ar'; // Determine language direction
  return (
    <div className="slider">
      <video
        ref={videoRef} // Attach the ref to the video element
        className="video-iframe"
        src={videos[currentIndex]}
        // controls
        autoPlay
        muted
        loop={false}
      ></video>
      {/* Gray Overlay */}
      <div className="video-overlay"></div>
      {/* Arrows for navigation */}
      <div className="arrow left" onClick={isRTL ? nextVideo : prevVideo}>
        {isRTL ? '\u276F' : '\u276E'}
      </div>
      <div className="arrow right" onClick={isRTL ? prevVideo : nextVideo}>
        {isRTL ? '\u276E' : '\u276F'}
      </div>

      {/* Dots indicator */}
      <div className="dots">
        {videos.map((_, index) => (
          <span key={index} className={index === currentIndex ? 'active' : ''}></span>
        ))}
      </div>

      {/* Progress Bar */}
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
    </div>
  );
};

export default Slider;
