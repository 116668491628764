import React from 'react';
import '../styles/AboutUs.css'; // Create and update this CSS file
import bg from "../assets/images/pexels-mikebirdy-120049.jpg"; // Background image for the banner
import Footer from '../components/Footer';
import MetricsSection from '../components/MetricsSection'; // Import the new MetricsSection
import FounderSection from '../components/FounderSection'; // Import the new FounderSection
import OurLocationsSection from '../components/OurLocationsSection'; // Import the new FounderSection
import logo from '../assets/images/Falcons Lux Logo.png'; // Import your image here
import imge1 from '../assets/images/pexels-lynxexotics-4141954.jpg'; // Import your image here
import imge2 from '../assets/images/pexels-boris-ivas-28180462-11603368.jpg'; // Import your image here
import imge3 from '../assets/images/pexels-johannes-treier-1312189-5094817.jpg'; // Import your image here
import imge4 from '../assets/images/pexels-gustavo-fring-4173201.jpg'; // Import your image here
import imge5 from '../assets/images/pexels-gustavo-fring-4173197.jpg'; // Import your image here
import { useTranslation } from 'react-i18next';
const AboutUs = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl'; // Check if the language is RTL
  return (
    <div className="about-page">
      {/* Banner Section */}
      <div className="banner_about">
        <div className="overlay_about">
          {/* <h1>About Us</h1> */}
        </div>
      </div>
      <div className="elite-container" style={{ backgroundColor: "#000" }}>
        <div className="text-content">
          <h2 className="title">{t('ABOUT_US_TITLE')}</h2>
          <p className="description">
            {t('ABOUT_US_DESCRIPTION')}
          </p>
        </div>
        <div className="image-content">
          <img
            src={imge1}
            alt="Elite Group Holding"
            className="elite-image"
          />
        </div>
      </div>
      <div className="container_a">
        {/* First Section */}
        <div className="section_a">
        <div className={`image-wrapper ${isRTL ? 'right-image' : 'left-image'}`}>
            <img src={imge2} alt="Automotive" />
          </div>
          <div className="content-wrapper_a">
            <h2>{t('OUR_MISSION_TITLE')}</h2>
            <p>
              {t('OUR_MISSION_DESCRIPTION')}
            </p>
            <button>{t('EXPLORE_BUTTON')}</button>
          </div>
        </div>

        {/* Second Section */}
        <div className="section_a">
          <div className="content-wrapper_a">
            <h2>{t('OUR_PROMISE_TITLE')}</h2>
            <p>{t('OUR_PROMISE_DESCRIPTION')}</p>
            <button>{t('EXPLORE_BUTTON')}</button>
          </div>
          <div className={`image-wrapper ${isRTL ? 'left-image' : 'right-image'}`}>
            <img src={imge3} alt="E-Commerce" />
          </div>
        </div>
      </div>
      <div className="container_a">
        {/* First Section */}
        <div className="section_a">
        <div className={`image-wrapper ${isRTL ? 'right-image' : 'left-image'}`}>
            <img src={imge4} alt="Automotive" />
          </div>
          <div className="content-wrapper_a">
            <h2>{t('WHY_CHOOSE_US_TITLE')}</h2>
            <p>{t('WHY_CHOOSE_US_DESCRIPTION')}</p>
            <button>{t('EXPLORE_BUTTON')}</button>
          </div>
        </div>

        {/* Second Section */}
        <div className="section_a">
          <div className="content-wrapper_a">
            <h2>{t('VISIT_US_TITLE')}</h2>
            <p>{t('VISIT_US_DESCRIPTION')}</p>
            <button>{t('EXPLORE_BUTTON')}</button>
          </div>
          <div className={`image-wrapper ${isRTL ? 'left-image' : 'right-image'}`}>
            <img src={imge5} alt="E-Commerce" />
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default AboutUs;
