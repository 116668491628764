// import React from 'react';
import '../styles/Navbar.css';
import logo from '../assets/images/Falcons Lux Logo.png'; // Import your image here
import i18next from "../i18n/config";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
const SpecialNavbar = () => {
  const vehicles = [
    { name: 'All Vehicles' },
    { name: 'Sedans' },
    { name: 'SUV' },
    { name: 'Hatchback' },
    { name: 'Trucks' },
    { name: 'Pick Up' },
    { name: 'Buses & Vans' },
  ];
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  // Toggle submenu visibility on hover or click
  const handleMouseEnter = () => {
    setIsSubmenuOpen(true);
  };

  const handleMouseLeave = () => {
    setIsSubmenuOpen(false);
  };
  return (
    <nav className="navbar">
      {/* Left side: Menu icon and links */}
      <div className="navbar-left">
        {/*  */}
      </div>

      {/* Center: Logo */}
      <div className="navbar-logo">
        <img src={logo} alt="Logo" className="logo" />
      </div>

      {/* Right side: Links */}
      <div className="navbar-right">
        {/* <ul className="nav-links">
          <li><Link to="/top-offers">{i18next.t("OFFERS")}</Link></li>
          <li><Link to="/about-us">{i18next.t("ABOUT_US")}</Link></li>
          <li><Link to="/contact">{i18next.t("CONTACT_US")}</Link></li>
        </ul> */}
      </div>
    </nav>
  );
};

export default SpecialNavbar;
