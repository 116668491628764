import React, { useState } from 'react';
import '../styles/CarGalleryM.css';
import Slider from "react-slick";
import Left_Image from '../assets/images/5 years.png';
import Right_Image from '../assets/images/2 years.png';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import SpeedIcon from '@mui/icons-material/Speed';
import BuildIcon from '@mui/icons-material/Build';
const CarGalleryM = () => {
  const carData = {
    title: 'Mercedes-Benz C200 2025',
    model: '',
    price: '',
    vat: '',
    images: [
      require('../assets/images/1.jpg'),
      require('../assets/images/2.jpg'),
      require('../assets/images/3.jpg'),
      require('../assets/images/4.jpg'),
      require('../assets/images/5.jpg'),
      require('../assets/images/6.jpg'),
      require('../assets/images/7.jpg'),
      require('../assets/images/08.jpg'),
      require('../assets/images/9.jpg'),
      require('../assets/images/10.jpg'),
      require('../assets/images/11.jpg'),
      require('../assets/images/12.jpg'),
      require('../assets/images/13.jpg'),
      require('../assets/images/14.jpg'),
      require('../assets/images/15.jpg'),
      require('../assets/images/16.jpg'),
      require('../assets/images/17.jpg'),
      require('../assets/images/18.jpg'),
      require('../assets/images/19.jpg'),
      require('../assets/images/20.jpg'),
      require('../assets/images/21.jpg'),
      require('../assets/images/24.jpg'),
    ],
    specs: [
      { icon: <CalendarTodayIcon sx={{ color: '#CD9834', fontSize: 40 }} />, label: 'Registration', value: '2025' },
      { icon: <LocalGasStationIcon sx={{ color: '#CD9834', fontSize: 40 }} />, label: 'Fuel Type', value: 'Petrol' },
      { icon: <DirectionsCarIcon sx={{ color: '#CD9834', fontSize: 40 }} />, label: 'Transmission', value: 'Automatic' },
      { icon: <SpeedIcon sx={{ color: '#CD9834', fontSize: 40 }} />, label: 'Horsepower', value: '204 HP' },
      { icon: <BuildIcon sx={{ color: '#CD9834', fontSize: 40 }} />, label: 'Body Type', value: 'Sedan' },
      { label: 'Exterior Colour', value: 'Black Gray White' },
    ],
  };

  const [mainImage, setMainImage] = useState(carData.images[0]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    beforeChange: (current, next) => setMainImage(carData.images[next]), // Update main image on slide change
    responsive: [
      { breakpoint: 768, settings: { slidesToShow: 3 } },
      { breakpoint: 480, settings: { slidesToShow: 2 } },
    ],
  };

  return (
    <div className="car-gallery-M">
      <div className="two-image-section">
        <img src={Left_Image} alt="Left Image" className="side-image" />
        <img src={Right_Image} alt="Right Image" className="side-image" />
      </div>
      <div className="car-header-M">
        <h2>{carData.title}</h2>
        <div className="car-price-M">
          <span>{carData.price}</span> <small>{carData.vat}</small>
        </div>
        <p>{carData.model}</p>
      </div>

      <div className="main-image-container-M">
        <img src={mainImage} alt="Car" className="main-image-M" />
      </div>

      <Slider {...settings} className="thumbnail-gallery-M">
        {carData.images.map((image, index) => (
          <div key={index} className="thumbnail-slide-M">
            <img
              src={image}
              alt={`Thumbnail-M ${index + 1}`}
              className="thumbnail-M"
              onClick={() => setMainImage(image)} // Optional: Allow manual click to change main image
            />
          </div>
        ))}
      </Slider>

      <div className="specs-container-M">
        <div className="specs-row-M">
          {carData.specs.slice(0, 5).map((spec, index) => (
            <div key={index} className="spec-icon-item-M">
              <div className="icon-M">{spec.icon}</div>
              <span className="spec-label-M">{spec.value}</span>
            </div>
          ))}
        </div>

        <hr className="divider-M" />

        <div className="spec-details-M">
          {carData.specs.map((spec, index) => (
            <div key={index} className="spec-item-M">
              <span className="spec-name-M">{spec.label}</span>
              <span className="spec-value-M">{spec.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarGalleryM;
