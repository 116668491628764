import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/ThankYouPage.css'; // Create this CSS file to style the page
import Footer from '../components/Footer';
import imge from "../assets/images/pexels-savvas-stavrinos-270619-814544.jpg";
function ThankYouPage() {
  const navigate = useNavigate();

  return (
    <div>
    <div className="thank-you-page">
      <div className="thank-you-content">
        <h1>Thank You!</h1>
        <p>Your submission has been received successfully.</p>
        <p>We appreciate your interest and will be in touch soon.</p>
        
        {/* Optional image */}
        <img src={imge} alt="Thank You" className="thank-you-image" />

        <button className="thank-you-button" onClick={() => navigate('/home')}>Return to Homepage</button>
      </div>
      
    </div>
    <Footer />
    </div>
  );
}

export default ThankYouPage;
