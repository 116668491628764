import logo from './logo.svg';
import Navbar from './components/Navbar';
import SpecialNavbar from './components/SpecialNavbar';
import Slider from './components/Slider';
import StockCarousel from "./components/StockCarousel";
import Footer from "./components/Footer";
import SocialMediaSection from './components/SocialMediaSection';
import GallerySection from './components/GallerySection';
import StatisticsSection from './components/StatisticsSection';
import WhyUsSection from './components/WhyUsSection';
import TopOffers from './components/TopOffers';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate , useLocation} from 'react-router-dom';
import Home from './pages/Home'; // Create a Home component for the home page
import ContactUs from './pages/ContactUs'; // Import the Contact Us page component
import AboutUs from './pages/AboutUs'; // Import the About Us page component
import Gallery from './pages/Gallery'; // Import the Gallery page component
import TopOffersPage from './pages/TopOffersPage'; // Import the TopOffers page component
import VehiclesPage from './pages/VehiclesPage'; // Import the VehiclesPage page component
import VehiclesModelPage from './pages/VehiclesModelPage'; // Import the VehiclesPage page component
import VehiclesSearchPage from './pages/VehiclesSearchPage'; // Import the VehiclesPage page component
import CarDetailsPage from './pages/CarDetailsPage'; // Import the CarDetailsPage page component
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'; // Import the privacy policy page component
import LandingPage from './pages/LandingPage'; // Import the landing page component
import LandingPageNoForm from './pages/LandingPageNoForm'; // Import the LandingPageNoForm component
import ThankYouPage from './pages/ThankYouPage'; // Import the LandingPageNoForm component
import VideoGallery from './pages/VideoGallery'; // Import the VideoGallery component
// import CookiePolicyDialog  from './components/CookiePolicyDialog'; // Import the VideoGallery component
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import WhatsAppButton from './components/WhatsAppButton';
import FloatingInquiryButton from './components/FloatingInquiryButton';
function MainLayout() {
  const location = useLocation();
  const isSpecialRoute = location.pathname === '/page/mc_c200';
  const isSpecialRoute2 = location.pathname === '/page/c200';

  return (
    <>
      {isSpecialRoute || isSpecialRoute2 ? <SpecialNavbar /> : <Navbar />}
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/videogallery" element={<VideoGallery />} />
        <Route path="/top-offers" element={<TopOffersPage />} />
        <Route path="/vehicles-page" element={<VehiclesPage />} />
        <Route path="/vehicles-model-page" element={<VehiclesModelPage />} />
        <Route path="/vehicles-search-page" element={<VehiclesSearchPage />} />
        <Route path="/car/:carId" element={<CarDetailsPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/page/mc_c200" element={<LandingPage />} />
        <Route path="/page/c200" element={<LandingPageNoForm />} />
        <Route path="/ThankYouPage" element={<ThankYouPage />} />
        {/* <Route path="/CookiePolicyDialog" element={<CookiePolicyDialog  />} /> */}
      </Routes>
    </>
  );
}

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);
  return (
    <Router>
      <MainLayout />
      <WhatsAppButton />
      <FloatingInquiryButton />
    </Router>
  );
}
export default App;
